import { Box, Button, Checkbox, Container, Grid, Typography,  InputAdornment, MenuItem, Select } from '@mui/material'
import React, { useState,useRef, useEffect } from 'react'
import "./signUP.styles.css"
import InputField from '../mui-components/InputField'
import logo from "../assets/images/Logo-new.svg"
import { useForm } from "react-hook-form"
import { aguserStore, configStore, loginStore } from "../state/CommonState";
import Carousel from '../mui-components/Carousel'
import { useSnapshot } from "valtio";
import Api from "../state/Api";
import UsersApis from '../state/users-api'
import AguserApi from "../state/AguserApi";
import { AguserType } from '../state/Types'
// import { useMode } from '../context/AuthContext'
import { APP_VERSION } from '../util/constants'
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

function LoginPageNew({callback, source}) {
  // const {setMode : callback} = useMode();
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const [isToggled, setIsToggled] = useState(true)
  const [submitType, setSubmitType] = useState("signInEmail");

  const isEmailSignin = submitType === "signInEmail"

  // const onSubmit = async (event) => {
  //   event.preventDefault(); 
    
  //   const data = new FormData(event.target);
  //   const formData = {
  //     email: data.get('email'),
  //     password: data.get('password'),
  //     rememberMe: data.get('rememberMe') === 'on',
  //     mobile: data.get('mobile'),
  //     code: data.get('code')
  //   };
  
  //   submit(formData);
  // };

  const handleChange = (type,e)=>{
    // setLoading(false)
    console.log(e.target.value);
    if (type === "email") {
      e.target.value = e.target?.value?.toLowerCase()
      let emailCheck = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      let ok =   emailCheck.test(e.target.value)
      console.log(ok);
      ok?setError(""):setError("Invalid email address")
    } else if (type === "mobile") {
      e.target.value = e.target?.value?.toLowerCase()
      let numberCheck = /^[0-9]{7,15}$/
      let ok =   numberCheck.test(e.target.value)
      console.log(ok);
      ok?setError(""):setError("Mobile number must be between 7 to 15 digits")
    }
  }

  const loginForm = useForm({
      defaultValues:{
          email:"",
          password:"",
          rememberMe : true,
          mobile: "",
          code:""
      }
  })

  const {handleSubmit,register,formState:{errors},control,getValues,setValue,watch,reset} = loginForm;

  useSnapshot(configStore);
  const submit = async (data)=>{
      console.log("The data is ",data)
      setLoading(true);
      try {
          // await Api.getUser(data.email.toLowerCase(), data.password,(userRec)=>{
          //     if(!userRec?.userFound){
          //         return  configStore.setModalMessage("Invalid username/password")
          //       }
          //       loginStore.isShowClientDashBoard = userRec?.aguserRec?.role
          //       .toLowerCase()
          //       .startsWith("client");
          //       if(userRec?.aguserRec?.token && userRec?.aguserRec?.token.length>0){
          //           localStorage.setItem('devicetoken', userRec.aguserRec.token)
          //           aguserStore.aguserRec.set(userRec.aguserRec);
          //           if(userRec?.aguserRec?.selectgst && userRec?.aguserRec?.selectgst.length>0){
          //             console.log("the selected  gst is ",userRec.aguserRec.selectgst)
          //             console.log("the company list is ",userRec.companyList)
          //               for (let i = 0; i < userRec.companyList.length; i++) {
          //                   if(userRec.companyList[i]['_id'] == userRec.aguserRec.selectgst){
          //                       localStorage.setItem('ag_current_entity', JSON.stringify(userRec.companyList[i]))
          //                       configStore.currentSelectedEntity = userRec.companyList[i];
          //                   }
          //               }
          //           }
          //           if(userRec?.aguserRec?.tour && userRec?.aguserRec?.tour.length>0){
          //               localStorage.setItem('tour', userRec.aguserRec.tour)
          //           }  
          //           const userRecString = JSON.stringify(userRec)
          //           console.log("the rememebere ejenej ",data.rememberMe)
          //           localStorage.setItem('ag_email', data.email)
          //           if (data.rememberMe) {
          //               localStorage.setItem("ag_remember_user",true);
          //               localStorage.setItem('ag_userrec', userRecString)
          //           }else{
          //             localStorage.setItem("ag_remember_user",false);
          //             sessionStorage.setItem('ag_userrec', userRecString)
          //           }

          //           loginStore.email = data.email; 
          //           reset();
          //         //   navigate('/',{replace:true})
          //       }
          // })
          if(isEmailSignin) {
            if(data?.email && data?.password){
          await UsersApis.loginUser(data , (userRec)=> {
            console.log(userRec);
            setLoading(false);
            if(!userRec?.userFound){
              return  configStore.setModalMessage(userRec?.message)
            }
            if(userRec?.userFound && userRec.entityList.length === 0 && userRec?.noEntity){
              localStorage.setItem('ag_email', data.email)
              localStorage.setItem('version', APP_VERSION )
              localStorage.setItem('tour', userRec?.aguserRec?.tour)
              localStorage.setItem('devicetoken', userRec?.aguserRec?.token)
              loginStore.isShowClientDashBoard = userRec?.aguserRec?.role
              loginStore.userRec.aguserRec = userRec?.aguserRec
              loginStore.email = data.email; 
              const userRecString = JSON.stringify(userRec)
              if (data.rememberMe) {
              localStorage.setItem("ag_remember_user",true);
              localStorage.setItem('ag_userrec', userRecString)
              }else{
              localStorage.setItem("ag_remember_user",false);
            // sessionStorage.setItem('ag_userrec', userRecString)
             }
            return  callback("entity")
            }
            loginStore.isShowClientDashBoard = userRec?.aguserRec?.role.some(role => role
              .toLowerCase()
            .startsWith("client"));  
            if(userRec?.aguserRec?.token && userRec?.aguserRec?.token.length>0){
                        localStorage.setItem('devicetoken', userRec.aguserRec.token)
                        if(userRec?.aguserRec?.selectgst && userRec?.aguserRec?.selectgst.length>0){
                            for (let i = 0; i < userRec.companyList.length; i++) {
                              for(let r = 0 ; r < userRec.companyList[i].branches.length; r++ ){
                                if(userRec.companyList[i]?.branches[r]?.gst == userRec.aguserRec.selectgst){
                                  localStorage.setItem('ag_current_entity', JSON.stringify(userRec.companyList[i]))
                                  localStorage.setItem('ag_current_branch', JSON.stringify(userRec.companyList[i].branches[r]))
                          configStore.currentSelectedEntity=userRec.companyList[i]
                          configStore.currentSelectedBranch=userRec.companyList[i].branches[r]
                              }
                              }
                               
                            }
                        }else {
                          if(userRec.companyList.length > 0 ) {
                            localStorage.setItem('ag_current_entity', JSON.stringify(userRec.companyList[0]))
                            localStorage.setItem('ag_current_branch', JSON.stringify(userRec.companyList[0].branches[0]))
                            configStore.currentSelectedEntity=userRec.companyList[0]
                            configStore.currentSelectedBranch=userRec.companyList[0].branches[0]
                          }

                        }
                        // localStorage.setItem('ag_current_entity', JSON.stringify(userRec.[i]))
                        // localStorage.setItem('ag_current_branch', JSON.stringify(userRec.companyList[i]))
                        if(userRec?.aguserRec?.tour && userRec?.aguserRec?.tour.length>0){
                            localStorage.setItem('tour', userRec.aguserRec.tour)
                        }
                        localStorage.setItem('ag_email', data.email)
                        localStorage.setItem('version', APP_VERSION )
                        // const rememberMe = localStorage.getItem('ag_remember_user')
                        // if (rememberMe === 'REMEMBER') {
                        //     const userRecString = JSON.stringify(userRec)
                        //     localStorage.setItem('ag_userrec', userRecString)
                        // }
                        const userRecString = JSON.stringify(userRec)
                        if (data.rememberMe) {
                        localStorage.setItem("ag_remember_user",true);
                        localStorage.setItem('ag_userrec', userRecString)
                    }else{
                      localStorage.setItem("ag_remember_user",false);
                      // sessionStorage.setItem('ag_userrec', userRecString)
                    }
                    }
        })
      } else{
        setLoading(false);
        if(!data?.email)
          
        // error.email.message = "Email is required"
      setError("Email is required")
      else if(!data?.password){
        // error.password.message = "Password is required"
      }
        // setError("Password is required")
      }
    } else {
      if(data?.mobile?.length !== 0){
      if(data?.mobile?.length >= 7 && data?.mobile?.length <= 15 ) {
            const isVerified = await verifyCheck(data)

            if (isVerified) {
              localStorage.setItem("OtpVerify", aguserStore?.aguserRec?.email)
              console.log(" OTP send...")
              await AguserApi.GetMobileOTP({ userRec: aguserStore.aguserRec, source: "login" }, (result) => {
                if (result?.data === 'failure' && result.message) {
                  // setOTPMsg(result.message)
                  setLoading(false)
                  // setError("OTP failed due to server error")

                  if(result.message === "Unable to deliver OTP. Please check your network."){
                      setError(result.message)
                      console.log("Failed", result.message)
                      aguserStore.aguserRec = new AguserType()
                  }else{
                   callback("verifyEmail")
                    source(result.message)
                  }
                 
                } else {
                  setLoading(false)
                  console.log("Success")
                  callback("verifyEmail")
                  source("loginOTP")
                }
              })
            } else {
              setLoading(false)
              if(!aguserStore.aguserRec) {
                  // no user
                  aguserStore.aguserRec = new AguserType()
                  setError("The number you entered isn't registered. Please check and try again.")
              } else if (!aguserStore?.aguserRec?.mobileVerified) {
                  // not verified   
                  aguserStore.aguserRec = new AguserType() 
                  setError("The number you entered isn't verified. Please check and try again.")
              }
           
            }
          } else {
            setLoading(false)
            setError("Mobile number must be between 7 to 15 digits")
          }
            // aguserStore.aguserRec.phone = data.mobile;
        }else {
          setLoading(false)
          setError("Mobile number is required")
        }
            
            // callback("verifyEmail");
            // configStore.setModalMessage("Dummy OTP has been sent to your email ID");
      }
      } catch (error) {
        setLoading(false)
         configStore.setModalMessage("Something went wrong")
      }
  }
  const verifyCheck = async (data) => {
    try {
      await UsersApis.getProfileByMobile(data.mobile, (userRec) => {
        if(userRec.status.ok)
        console.log("Profile Data:", userRec.data);
        aguserStore.aguserRec = userRec?.data
      });
      return ((aguserStore?.aguserRec?.phone && aguserStore?.aguserRec?.phonePrefix === '+91' && aguserStore?.aguserRec?.mobileVerified ) )
    } catch (error) {
      console.error("Error in verifyCheck:", error);
    }
  };

  const startIcon = submitType === "signInEmail" ? <SmartphoneIcon /> : <EmailOutlinedIcon />;

const handleSignInEmail = () => {
  // setLoading(true)
  setValue('mobile','') 
  console.log("Signing in...");
};
const handleGetOtp = () => {
  // setLoading(true)
  setValue('email','') 
  setValue('password','') 
  console.log("Signing in...");
};

const handleOnSubmit = () => {
  setError("")
  setSubmitType(prevSubmitType => prevSubmitType === "signInEmail" ? "signInMobile" : "signInEmail");

}

  return (
    <>
  <div className='signIN_container'>
    <div className='info_container'>
      <div className='logo_container'>
      <img src={logo}></img>
      </div>
    <Typography variant="h3" fontFamily={"Figtree, sans-serif"} fontWeight={900} marginBottom={"1rem"} gutterBottom>Lead your supply chain
    with ease.</Typography>
    <Typography variant="h6" fontFamily={"Figtree, sans-serif"} color={"#555"} fontWeight={400} marginBottom={"1rem"} gutterBottom>Experience seamless solutions, Transparent value chains and Boundless trade!</Typography>
    <Carousel/>
    </div>
    <div className='field_container'>
    <div className='logo_container_for_mobile'>
         <img src={logo}></img>
    </div>
{/* <form className="field_wrapper" onSubmit={(e) => (onSubmit(e))}> */}
<form className="field_wrapper" onSubmit={handleSubmit(submit)}>
            <Typography variant="h5" fontFamily={"Figtree, sans-serif"} fontWeight={600} marginBottom={"1rem"} gutterBottom>Sign in to Agraga</Typography>
            {isEmailSignin ? (
              <>
                <InputField
                  placeholder={"Enter Email"}
                  label={"Email"}
                  label_class={"label_styles"}
                  mandatory={false}
                  input_class={"inputBox_styles"}
                  errorsmsg={errors?.email?.message || error}
                  input_wrapeer={"input_wrapeer"}
                  type={"email"}
                  // onkeyup={(e)=> e.target.value = e.target?.value?.toLowerCase()}
                  children={{
                    ...register("email", {
                      onChange: (e) => handleChange("email", e), required: "Email is Required",
                      pattern: {
                        value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: `Invalid email address`
                      }
                    })
                  }}
                  callback={(e) => console.log(e)}
                  name={"email"}
                />
                <p className="fogot_password clr_blue" onClick={() => callback('reset')}>Forgot password?</p>
                <InputField
                  placeholder={"Enter Password"}
                  label={"Password"}
                  label_class={"label_styles"}
                  mandatory={false}
                  input_class={"inputBox_styles edgeEyeHide"}
                  type={"password"}
                  id={"changeType"}
                  errorsmsg={errors?.password?.message}
                  children={{ ...register("password", { onChange: (e) => handleChange("password", e), required: "Password is Required" }) }}
                  callback={(e) => console.log(e)}
                  name={"password"}
                  container={"input_icon_styles"}
                  icon_childer={true}
                />
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center", alignSelf: "start" }}>
                  <Checkbox
                    style={{ paddingLeft: 0 }}
                    checked={isToggled}
                    name="rememberMe"
                    {...register("rememberMe", { onChange: (e) => setIsToggled(e.target.checked) })}
                  />
                  <Typography className="Remember_me">Remember me</Typography>
                </Box>
              </>
            ) : (
                <InputField
                  label={"Mobile Number"}
                  register={register}
                  errors={errors}
                  placeholder={"Enter Mobile Number"}
                  label_class={"label_styles"}
                  mandatory={false}
                  input_class={"inputBox_styles"}
                  errorsmsg={errors?.mobile?.message || error}
                  input_wrapeer={"input_wrapeer"}
                  type={"text"}
                  children={{
                    //  <><CountryCodeDropdown register={register} /><input{
                    ...register("mobile", {
                      onChange: (e) => handleChange("mobile", e), required: "Mobile number is Required",
                      pattern: {
                        value: /^[0-9]{7,15}$/,
                        message: "Mobile Number must be between 7 to 15 digits"
                      }
                    })
                    // } /></>
                  }}
                  callback={(e) => console.log(e)}
                  name={"mobile"}
                />
            )}
            {/* <SignInButtons submitType={submitType} onClick={setSubmitType} callback={callback}/> */}
            <Button
                variant="contained"
                className="btn_color"
                type="submit"
                fullWidth
                callback={(e)=>console.log(e)}
                disabled={loading}
                onClick={() => {
                    if (submitType === "signInEmail") {
                        handleSignInEmail(); 
                    } else {
                        handleGetOtp(); 
                    }
                }}>
                {submitType === "signInEmail" ? "Sign In" : "Send OTP"}</Button>
            <Box className="divider">
                <Typography className="divider-text">OR</Typography>
            </Box>
            <Button
                variant="outlined"
                className="btn_no_color"
                type="button"
                startIcon={startIcon}
                fullWidth
                onClick = {handleOnSubmit}>
                {/* onClick={() => setSubmitType(submitType === "signInEmail" ? "signInMobile" : "signInEmail")}> */}
                {submitType === "signInEmail" ? "Sign In with OTP" : "Sign In with Email"}
            </Button>
            </form>
      
      <p className='note_info2'>Don’t have an account yet?<span className='clr_blue' onClick={()=>{
        console.log("aguserstore : ", aguserStore);
                aguserStore.aguserRec = new AguserType()
            callback('register') 
          }}> Sign Up</span></p>
              <p className='note_info2'>  <span className='clr_blue' onClick={()=>{
            callback('trackshipment') 
          }}>Back to Track Shipment</span></p>
    </div>

  </div>
    
    </> 
  )
}


export default LoginPageNew