import { useSnapshot } from "valtio";
import { myactionsstore } from "./store-myactions";
import { configStore } from "../state/CommonState";
import { OneDatetable, OneInput5table } from "../mytasks/act-common";
import { useEffect, useState } from "react";

export function Vviewmodal(props) {
  useSnapshot(myactionsstore);
  useSnapshot(configStore);
  let screen = props.screen
  const [ch, setCh] = useState(0);
  const [orgin, setOrgin] = useState([]);
  const [des, setDes] = useState([]);
  // let orgin = []
  // let des = []
  let ret = configStore.modalvisibledata;
  let st = ret.shipmentType;
  let sc = ret.shipmentScope;

  const seorde = () => {
    if (sc == "Port-to-Door" || sc == "Door-to-Door") {
        let a = []
        for (let i = 0; i < screen; i++) {
            if(i==0){
                if(ret.vdes != undefined && ret.vdes.length > 0) {
                    a.push(ret.vdes)
                }else{
                    a.push([])
                }
            }else{
                let k = i+1
                if(ret['vdes'+k] != undefined && ret['vdes'+k].length > 0) {
                    a.push(ret['vdes'+k])
                }else{
                    a.push([])
                }
            }
        }
        setDes(a)
    }
    if (sc == "Door-to-Port" || sc == "Door-to-Door") {
      if (ret.vorgin != undefined && ret.vorgin.length > 0) {
        setOrgin(ret.vorgin);
      }
    }
    changech();
  };

  const changech = () => {
    if (ch == 0) {
      setCh(1);
    } else {
      setCh(0);
    }
  };

  useEffect(() => {
    if (myactionsstore.vviewModalVisible == true) {
      seorde();
    }
  }, [myactionsstore.vviewModalVisible]);

  useEffect(() => {
    if (ret.total_package == undefined || ret.total_package.length == 0) {
      if (
        configStore.modalvisibledata &&
        configStore.modalvisibledata.booking &&
        configStore.modalvisibledata.booking.contract &&
        configStore.modalvisibledata.booking.contract.cargoTotals &&
        configStore.modalvisibledata.booking.contract.cargoTotals.totNumPackages
      ) {
        ret.total_package =
          configStore.modalvisibledata.booking.contract.cargoTotals.totNumPackages;
      }
    }
  }, [configStore.modalvisibledata, ret.vdes]);

  useEffect(()=>{
    if(myactionsstore.vviewModalVisible==true){
        seorde()
    }
},[myactionsstore.vviewModalVisible])

useEffect(()=>{
    if(ret.total_package==undefined || ret.total_package.length==0){
        if((configStore.modalvisibledata && configStore.modalvisibledata.booking && configStore.modalvisibledata.booking.contract && configStore.modalvisibledata.booking.contract.cargoTotals && configStore.modalvisibledata.booking.contract.cargoTotals.totNumPackages)){
            ret.total_package = configStore.modalvisibledata.booking.contract.cargoTotals.totNumPackages
        }
    }
},[configStore.modalvisibledata,ret.vdes])

const onsubmit2 = () =>{
    if(orgin.length>0){
        ret.vorgin = orgin
    }else{
        ret.vorgin = []
    }
    if(des.length>0){
        ret.vdes = des
    }else{
        ret.vdes = []
    }
    configStore.modalvisibledata = ret
    myactionsstore.vviewModalVisible = false
}
let modalClass = 'modal'
if (myactionsstore.vviewModalVisible) modalClass += ' is-active'
return (
    <>
        {
            (myactionsstore.vviewModalVisible) ? <div className={modalClass} onClick={() => { myactionsstore.vviewModalVisible = false }}>
                <div className="modal-background" ></div>
                <div className="modal-card" onClick={(e) => { e.stopPropagation() }} style={{ borderRadius: '10px', width: "90%" }} >
                    <section className="modal-card-body"  >
                        <div className="table-container" onClick={(e) => e.stopPropagation()}>
                        {   
                                ((sc == "Door-to-Port" || sc == "Door-to-Door") && st != 'FCL (Factory/Dock)') ? <>
                                    <div>
                                        <div class="px-5 py-2 flex align-center justify-between w-3/5">
                                            <p class="text-base font-semibold">Origin Pick-up</p>
                                            <div class="flex align-center w-3/5">
                                                <p class="text-base font-semibold">Total No. of Packages Delivery:</p>
                                                <input type="number" value={ret.total_package} class="w-24 border-solid border rounded-md p-1.5">{}</input>
                                            </div>
                                        </div>
                                        <div class="p-5 bg-custom-gray rounded-md">
                                            <div class="flex justify-between">
                                                <div class="flex align-center">
                                                    <p class= 'text-sm font-semibold text-blue'>Status:</p>
                                                    <button class = 'w-24 h-8 mx-2 p-1 rounded-full'>{" - - - - - -"}</button>
                                                </div>
                                                <div class="flex align-center"><p class= 'text-sm font-semibold'>Vehicle {"Count"}</p></div>
                                                <div class="flex">
                                                    <button className="mr-2 button is-info is-inverted">
                                                        <span className="icon">
                                                        <i class="fas fa-edit"></i>
                                                        </span>
                                                    </button>
                                                    <button className="ml-2 button is-danger is-inverted">
                                                        <span className="icon">
                                                            <i className="fas fa-trash"></i>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="flex py-2">
                                                <div class = "flex w-3/5">
                                                    <div class="w-2/4 px-4">
                                                        <p class= 'text-sm'>Origin*</p>
                                                        <input type="text"  class="border-solid border w-full rounded-md p-1.5"/>
                                                    </div>
                                                    <div class="w-2/4 px-4">
                                                        <p class= 'text-sm'>Destination*</p>
                                                        <input type="text" disabled="true"  class="border-solid border w-full rounded-md p-1.5"/>
                                                    </div>
                                                </div> 
                                                <div class = "flex w-2/5">
                                                    <div class="w-2/4 px-4">
                                                        <p class= 'text-sm'>Vehicle Number*</p>
                                                        <input type="text"  class="border-solid border w-full rounded-md p-1.5"/>
                                                    </div>
                                                    <div class="w-2/4 px-4">
                                                        <p class= 'text-sm'>No. of Packages*</p>
                                                        <input type="text"  class="border-solid border w-full rounded-md p-1.5"/>
                                                    </div>
                                                </div> 
                                            </div>
                                            <div class="flex px-2 py-2">
                                                <div class = "w-full px-2">
                                                    <p class= 'text-sm'>Driver Number*</p>
                                                    <input type="tel" class = "border-solid border w-full rounded-md p-1.5"/>
                                                </div>
                                                <div class = "w-full p  x-2">
                                                    <p class= 'text-sm'>Driver Name</p>
                                                    <input type="text" class = "border-solid border w-full rounded-md p-1.5"/>
                                                </div>
                                                <div class = "w-full px-2">
                                                    <p class= 'text-sm'>Empty Container Pick-up</p>
                                                    <input type="date" class = "border-solid border w-full rounded-md p-1.5"/>
                                                </div>
                                                <div class = "w-full px-2">
                                                    <p class= 'text-sm'>Empty Container Arrival</p>
                                                    <input type="date" class = "border-solid border w-full rounded-md p-1.5"/>
                                                </div>
                                                <div class = "w-full px-2">
                                                    <p class= 'text-sm'>Loaded & Dispatched</p>
                                                    <input type="date" class = "border-solid border w-full rounded-md p-1.5"/>
                                                </div>
                                                <div class = "w-full px-2">
                                                    <p class= 'text-sm'>Container Handover at POR</p>
                                                    <input type="date" class = "border-solid border w-full rounded-md p-1.5"/>
                                                </div>
                                            </div>
                                            <div class="mt-2 pr-4 flex justify-end">
                                                <button class="w-24 h-9 border-0 px-4 py-2 border-transparent text-white bg-blue font-semibold rounded-md 
                                                hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400">
                                                    Track {"-->"}
                                                </button>
                                            </div>
                                        </div>
                                        <div class="mt-2 flex justify-end">
                                            <button class="w-24 h-9 border-0 border-slate-800 bg-custom-gray px-4 py-2 border-transparent text-black font-semibold rounded-md">
                                                + Add Row
                                            </button>
                                        </div>
                                        
                                    </div>
                                </> : <></>
                            }
                            {
                                ((sc == "Port-to-Door" || sc == "Door-to-Door") && st != 'FCL (Dock/Factory)') ? <>
                                    <div>
                                        <div class="px-5 py-2 flex align-center justify-between w-3/5">
                                            <p class="text-base font-semibold">Destination Delivery</p>
                                            <div class="flex align-center w-3/5">
                                                <p class="text-base font-semibold">Total No. of Packages Delivery:</p>
                                                <input type="number" value={ret.total_package} class="w-24 border-solid border rounded-md p-1.5"></input>
                                            </div>
                                        </div>
                                        <div class="p-5 bg-custom-gray rounded-md">
                                            <div class="flex justify-between">
                                                <div class="flex align-center">
                                                    <p class= 'text-sm font-semibold text-blue'>Status:</p>
                                                    <button class = 'w-24 h-8 mx-2 p-1 rounded-full'>{" - - - - - -"}</button>
                                                </div>
                                                <div class="flex align-center"><p class= 'text-sm font-semibold'>Vehicle {"Count"}</p></div>
                                                <div class="flex">
                                                    <button className="mr-2 button is-info is-inverted">
                                                        <span className="icon">
                                                        <i class="fas fa-edit"></i>
                                                        </span>
                                                    </button>
                                                    <button className="ml-2 button is-danger is-inverted">
                                                        <span className="icon">
                                                            <i className="fas fa-trash"></i>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="flex py-2">
                                                <div class = "flex w-3/5">
                                                    <div class="w-2/4 px-4">
                                                        <p class= 'text-sm'>Origin*</p>
                                                        <input type="text"  class="border-solid border w-full rounded-md p-1.5"/>
                                                    </div>
                                                    <div class="w-2/4 px-4">
                                                        <p class= 'text-sm'>Destination*</p>
                                                        <input type="text"  class="border-solid border w-full rounded-md p-1.5"/>
                                                    </div>
                                                </div> 
                                                <div class = "flex w-2/5">
                                                    <div class="w-2/4 px-4">
                                                        <p class= 'text-sm'>Vehicle Number*</p>
                                                        <input type="text"  class="border-solid border w-full rounded-md p-1.5"/>
                                                    </div>
                                                    <div class="w-2/4 px-4">
                                                        <p class= 'text-sm'>No. of Packages*</p>
                                                        <input type="text"  class="border-solid border w-full rounded-md p-1.5"/>
                                                    </div>
                                                </div> 
                                            </div>
                                            <div class="flex px-2 py-2">
                                                <div class = "w-full px-2">
                                                    <p class= 'text-sm'>Driver Number*</p>
                                                    <input type="tel" class = "border-solid border w-full rounded-md p-1.5"/>
                                                </div>
                                                <div class = "w-full p  x-2">
                                                    <p class= 'text-sm'>Driver Name</p>
                                                    <input type="text" class = "border-solid border w-full rounded-md p-1.5"/>
                                                </div>
                                                <div class = "w-full px-2">
                                                    <p class= 'text-sm'>Loaded at CFS</p>
                                                    <input type="date" class = "border-solid border w-full rounded-md p-1.5"/>
                                                </div>
                                                <div class = "w-full px-2">
                                                    <p class= 'text-sm'>Cargo Pick-up Date</p>
                                                    <input type="date" class = "border-solid border w-full rounded-md p-1.5"/>
                                                </div>
                                                <div class = "w-full px-2">
                                                    <p class= 'text-sm'>Estimated Delivery Date</p>
                                                    <input type="date" class = "border-solid border w-full rounded-md p-1.5"/>
                                                </div>
                                                <div class = "w-full px-2">
                                                    <p class= 'text-sm'>Actual Delivery Date</p>
                                                    <input type="date" class = "border-solid border w-full rounded-md p-1.5"/>
                                                </div>
                                            </div>
                                            <div class="mt-2 pr-4 flex justify-end">
                                                <button class="w-24 h-9 border-0 px-4 py-2 border-transparent text-white bg-blue font-semibold rounded-md 
                                                hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400">
                                                    Track {"-->"}
                                                </button>
                                            </div>
                                        </div>
                                        <div class="mt-2 flex justify-end">
                                            <button class="w-24 h-9 border-0 border-slate-800 bg-custom-gray px-4 py-2 border-transparent text-black font-semibold rounded-md">
                                                + Add Row
                                            </button>
                                        </div>
                                    </div>
                                </> : <></>
                            }
                            <div class="mt-5 flex justify-end">
                                <button class="w-24 mr-3 h-9 text-blue border-0 border-slate-800 bg-custom-gray px-4 py-2 border-transparent text-black font-semibold rounded-md">
                                    Cancel
                                </button>
                                <button class="w-24 ml-3 h-9 text-white bg-blue border-0 border-slate-800 bg-custom-gray px-4 py-2 border-transparent text-black font-semibold rounded-md">
                                    Submit  
                                </button>
                            </div>
                            {
                                ((sc == "Door-to-Port" || sc == "Door-to-Door") && st!='FCL (Factory/Dock)') ?
                                    <>
                                        <h1>Origin Pick-up</h1>
                                        <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                                            <thead>
                                                <th>S.No</th>
                                                <th>Vehicle#</th>
                                                <th>Arrival @ Factory</th>
                                                <th>Cargo Pickup</th>
                                                {
                                                    (st.toLowerCase().indexOf("fcl") != -1 || st.toLowerCase().indexOf("lcl") != -1) ? <>
                                                        <th>Cargo arrival at CFS</th>
                                                    </> : <>
                                                        <th>Arrival @ Dispatch Airport</th>
                                                    </>
                                                }

                                               
                                            </thead>
                                            <tbody>
                                                {
                                                    orgin.map((e, i) => {
                                                       return <tr key={i}>
                                                            <td>{i+1}</td> 
                                                            <td>{(e["vehicle"]?e["vehicle"]:"")}</td>
                                                            <td>{(e["arrival_factory"]?e["arrival_factory"]:"")}</td>
                                                            <td>{(e["cargo_pickup"]?e["cargo_pickup"]:"")}</td>
                                                            {
                                                                (st.toLowerCase().indexOf("fcl") != -1 || st.toLowerCase().indexOf("lcl") != -1) ? <>
                                                                    <td>{(e["cargo_arrival_cfs"]?e["cargo_arrival_cfs"]:"")}</td>
                                                                </> : <>
                                                                    <td>{(e["arrival_dispatch_airport"]?e["arrival_dispatch_airport"]:"")}</td>
                                                                </>
                                                            }
                                                        </tr>
                                                    })
                                                }
                                            </tbody>

                                        </table></> : ""
                            }


                            {
                                ((sc == "Port-to-Door" || sc == "Door-to-Door") && st!='FCL (Dock/Factory)') ? <>
                                    <h1>Destination Delivery</h1>
                                    {(ret.total_package && ret.total_package.length>0)?<h2>Total Package : {ret.total_package}</h2>:""}
                                    
                                    <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                                        <thead>
                                            <th>S.No</th>
                                            <th>Vehicle#</th>
                                            {
                                                (st.toLowerCase().indexOf("fcl") != -1 )?<th>Container Number</th>:""
                                            }
                                            <th>Number Of Package</th>
                                            {
                                                (st.toLowerCase().indexOf("fcl") != -1 || st.toLowerCase().indexOf("lcl") != -1) ? <>
                                                    <th>Loaded @ CFS</th>
                                                {
                                                    (st.toLowerCase().indexOf("lcl") != -1)?<th>Cargo Picked-up for Delivery</th>:<th>Dispatched from CFS</th>
                                                }
                                                    
                                                </> : <>
                                                    <th>Dispatched from Arrival Airport</th>
                                                </>
                                            }
                                            <th>Estimated Delivery Date to Consignee</th>
                                            <th>Actual Delivery Date</th>

                                          
                                        </thead>
                                        <tbody>
                                            {
                                                des.map((e, i) => {
                                                    return <tr>
                                                        <td>{i+1}</td>
                                                        <td>{(e["vehicle"]?e["vehicle"]:"")}</td>
                                                        {
                                                            (st.toLowerCase().indexOf("fcl") != -1 )?<td>{(e["container_number_vehicle"]?e["container_number_vehicle"]:"")}</td>:""
                                                        }
                                                        <td>{(e["number_of_package"]?e["number_of_package"]:"")}</td>
                                                        {
                                                            (st.toLowerCase().indexOf("fcl") != -1 || st.toLowerCase().indexOf("lcl") != -1) ? <>
                                                            
                                            
                                                                <td>{(e["loaded_cfs"]?e["loaded_cfs"]:"")}</td>
                                                                <td>{(e["atdfrompod"]?e["atdfrompod"]:"")}</td>
                                                            </> : <>
                                                                <th>{(e["dispatched_from_arrival_airport"]?e["dispatched_from_arrival_airport"]:"")}</th>
                                                            </>


                                                        }
                                                        <td>{(e["estimate_delivery_date_to_consignee"]?e["estimate_delivery_date_to_consignee"]:"")}</td>
                                                        <td>{(e["actual_delivery_date"]?e["actual_delivery_date"]:"")}</td>
                                                     
                                                    </tr>
                                                })
                                            }
                                        </tbody>

                                    </table></> : ""
                            }

                        </div>


                        <button class="button is-warning" onClick={() => myactionsstore.vviewModalVisible = false} >cancel</button>

                    </section>
                </div>
            </div> : <></>
        }
    </>
)
}
    
