import { useState } from "react";
import { useSnapshot } from "valtio";
import { taskStore } from "./task-store";
import Api from "../state/Api";
import { AgmodalStore, configStore, loginStore } from "../state/CommonState";
import { typeLabelMap } from "../StaticData";

export default function ScheduleDeviationsApproval(props) {
    useSnapshot(taskStore);
    useSnapshot(AgmodalStore);
    let deviation = taskStore.deviation;
    let task = taskStore.current;
    const [remark, setRemark] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const uploadFile = (file, key) => {
        if (!file) {
            // Handle the case where the file is not found by returning an empty string
            return Promise.resolve({ label: key, message: 'File not found', filename: "NA" });
        }

        const url = `${process.env.REACT_APP_FILE_SERVER}/api/v1/filestore/storefile`;
        const formData = new FormData();
        formData.append('myFile', file);
        formData.append('label', key);
        formData.append('key', task.bookingNum);

        return fetch(url, {
            method: 'POST',
            headers: Api.H1(),
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                if (data.error && data.error !== 'No Error' && data.error !== 'Invalid Token') {
                    throw new Error(data.error);
                }
                data.label = key;
                return data;
            })
            .catch(error => {
                console.error('File upload error:', error);
                throw error;
            });
    };


    const handleButtonClick = async () => {
        let fileResp = null;

        // Check if there is a file selected for upload
        if (selectedFile) {
            try {
                // Upload file to S3
                const uploadResponse = await uploadFile(selectedFile);
                if (uploadResponse?.message === "Success") {
                    fileResp = uploadResponse?.key;
                }
            } catch (error) {
                console.error("Error uploading file:", error);
                return;
            }
        }

        const req = {
            id: task.bookingNum,
            num: task._id.num,
            remarks: remark,
            mileStones: task.deviation,
            diffinDays: task.diffinDays,
            file: fileResp,
        };

        // Call the update API with S3 URL if available
        Api.updateScheduleDeviations(req);
        taskStore.myActionVisible = false;

        if (AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()) {
            AgmodalStore.pageVisible = false;
            AgmodalStore.apiCall = true;
        }
    };

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    return (
        <div className="box">
            <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                <thead>
                    <tr>
                        <th>
                            <h3 className="is-size-5 has-text-centered">Milestone</h3>
                        </th>
                        <th>
                            <h3 className="is-size-5 has-text-centered">Original Value</h3>
                        </th>
                        <th>
                            <h3 className="is-size-5 has-text-centered">New Value</h3>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {task.deviation?.map((e, i) => (
                        <tr key={e.id}>
                            <td className="has-text-centered">{typeLabelMap[e.type]?.label || e.type}</td>
                            <td className="has-text-centered">{e.originalValue}</td>
                            <td className="has-text-centered">{e.newValue}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="flex-container mt-4">
                <div className="flex-item">
                    {(task.remarks || task.status === "PENDING") && (
                        <div>
                            <p>Remarks:</p>
                            {(!task.remarks && task.status === "PENDING") ? (
                                <input
                                    className="input"
                                    type="text"
                                    value={remark}
                                    onChange={(e) => setRemark(e.target.value)}
                                />
                            ) : (
                                <p>{task.remarks}</p>
                            )}
                        </div>
                    )}
                </div>

                <div className="flex-item">
                    {task.status === "PENDING" && (
                        <div>
                            <p>Upload File:</p>
                            <input type="file" onChange={handleFileChange} />
                        </div>
                    )}
                    {task?.file?.filename && (
                        <p className="has-text-grey mt-2">
                            <strong>Uploaded File:</strong>
                            <a
                                href={`${process.env.REACT_APP_FILE_SERVER}/api/v1/filestore/getfile/${task?.bookingNum}/${task?.file?.filename}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {task?.file?.filename}
                            </a>
                        </p>
                    )}
                </div>
            </div>

            <div className="level-right" style={{ padding: "10px" }}>
                <div className="level-item">
                    {!task.remarks && task.status === "PENDING" && (
                        <p className="control">
                            <button
                                onClick={handleButtonClick}
                                className="button is-danger is-small is-rounded"
                                type="submit"
                            >
                                Submit
                            </button>
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
}
