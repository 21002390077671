import { useSnapshot } from "valtio"
import { configStore, contractsStore, entityStore, loginStore, bookingsStore, EntityV2Store } from "../state/CommonState"
import { $c, $cr } from '../components/AgComponents'
import EntityApi from "../state/entity-api"
import { useEffect, useState } from "react"
import LCLScheduleCompv3 from "./LCLScheduleComp-v3"
import { commodityList, hazardousList } from "../StaticData"
import { Agyesno } from "../components/Agyesno"
import FCLScheduleCompv3 from "./FCLScheduleComp-v3"
import AirScheduleCompv3 from "./AirScheduleComp-v3"
import { DummySchedulev3 } from "./dummy-schedule-v3"
import LCLPricingSchedule from "../pricing/lcl.schedule"
import AirPricingSchedule from "../pricing/air.schedule"
import BranchApi from "../state/branch-api"
import EntityV2Api from "../state/entities-api"
import ShipperApi from "../state/shipper-api"
import { taskStore } from "../mytasks/task-store"
import Utils from "../util/Utils"
import BookingsApi from "../state/bookings-api"

export default function CarrierWebView1v3(props) {
    console.log('INSIDE CARRIER WEBVIEW', props.showbooking)
    const [space, setSpace] = useState('')
    const [companyName, setCompanyName] = useState('')
    useSnapshot(loginStore)
    useSnapshot(bookingsStore)
    useSnapshot(contractsStore)
    useSnapshot(configStore)
    useSnapshot(entityStore)
    let brec = bookingsStore.current
    const aguser = loginStore.userRec.aguserRec
    useEffect(() => {
        EntityApi.getList()
        const ent = entityStore.list.filter(e1 => e1.gst === brec.company)
        if (ent.length > 0) {
            let entity = ent[0]
            setCompanyName(entity.entityName)
            setSpace( space === '' ? ' ' : '')
        }else{
            setCompanyName(brec.company)
        }
    }, [ bookingsStore.current])
    if (!brec) return <></>
    if (!brec.contract) return <></>
    console.log('GOT HERE ############################', brec.contract._id)
    contractsStore.current = brec.contract
    const domain = loginStore.email.split('@')[1]

    let title = 'Carrier Web Form'

    const entity1 = entityStore.entityRec
    const renderLink = (fileName) => {
        if (fileName && fileName.length > 3) {
            return (
                <a href={process.env.REACT_APP_API_SERVER + '/clientfiles/' + fileName} target="_blank">{fileName}</a>
            )
        } else return <></>
    }
    const filecat = '' + Date.now()
    const enableMBL = () => (contractsStore.current.shipmentType.indexOf('FCL') >= 0
        && contractsStore.current.shipmentScope.endsWith('ort')) ? {} : { disabled: 1 }
    let commList = commodityList
    if (brec.contract.hazardous === 'Yes') commList = hazardousList
    const stype = () => brec.contract.shipmentType.toUpperCase().substr(0, 3)
    console.log('==============FROM INSIDE CARRIERWEBVIEW', brec.contract.shipmentType.toUpperCase().substr(0, 3))
    let showbooking = {}
    if (props.showbooking === '1') showbooking = {showbooking: '1'}
    let Lclschedulecomponent = LCLScheduleCompv3
    let Airschedulecomponent = AirScheduleCompv3
    if (bookingsStore.current.createdFromPricing){
        if(stype()=="LCL"){
            Lclschedulecomponent = LCLPricingSchedule
        }
        if(stype()=="AIR"){
            Airschedulecomponent = AirPricingSchedule
        }
    } 
    console.log('\n\n\nfrompricing=', bookingsStore.current.createdFromPricing, '***\n\n')
    if(brec.fba==undefined){
        brec.fba = 'No'
    }
    return (
        <div style={{ fontSize: '0.8rem' }}>
            {
                bookingsStore.current?.hasToShowDummySchedule ? <DummySchedulev3 stype={stype()} viewonly={true} />
                : stype() === 'LCL' ?
                    <Lclschedulecomponent data={bookingsStore.current.schedule} viewonly={true} {...showbooking} nobutton={true}/> :
                    stype() === 'FCL' ?
                        <FCLScheduleCompv3 data={bookingsStore.current.schedule} viewonly={true} {...showbooking}/> :
                        <Airschedulecomponent viewonly={true} {...showbooking}/>
            }

            <div className="columns">
                <$cr n='2'>First Name{space}</$cr>
                <$c n='3'>
                    {brec.firstName}
                </$c>
                <$cr n='2'>Last Name</$cr>
                <$c n='3'>
                    {brec.lastName}
                </$c>
            </div>
            <div className="columns">
                <$cr n='2'>Email ID</$cr>
                <$c n='3'>
                    {brec.emailid}
                </$c>
                <$cr n='2'>Contact Number</$cr>
                <$c n='3'>
                    {brec.phone}
                </$c>
            </div>
            <div className="columns">
                <$cr n='2'>Shipper Name</$cr>
                <$c n='3'>
                    {companyName}
                </$c>
                <$cr n='2'>Address</$cr>
                <$c n='3'>
                    {brec.address}
                </$c>
            </div>
            <div className="columns is-narrow">
                <$cr n='2'>PIN/ZIP Code</$cr>
                <$c n='3'>
                    {brec.pin}
                </$c>
                <$cr n='2'>Country</$cr>
                <$c n='3'>
                    {brec.country}
                </$c>
            </div>

            <div className="columns">
                <$cr n='2'>Incoterms</$cr>
                <$c n='3'>
                    {brec.incoTerms}
                </$c>
                <$cr n='2'>Commodity Type</$cr>
                <$c n='3'>
                    {brec.commodityType}
                </$c>
            </div>
            {/* <div className="columns is-narrow">      
                <$cr n='2'>FBA</$cr>       
                <$c n='2'>
                    <Agyesno disabled={true} label='' record={brec} name='fba' />
                </$c>
                <$c n='8'>
                </$c>
            </div> */}
            <div className="columns is-narrow">
                <$cr n='2'>Special Instructions</$cr>
                <$c n='6'>
                    {brec.specialInstructions}
                </$c>
                <$c n='2'>
                    {
                        // (enableMBL().disabled)
                         (contractsStore.current.shipmentType.toLowerCase().includes('fcl') 
                         && contractsStore.current.shipmentScope.endsWith('ort')) ?
                            <Agyesno disabled={true} label='Do you require a Direct Carrier MBL?'
                                record={brec} name='carrierMBL' /> : null
                    }
                </$c>
            </div>

            <div className="columns">
                <$c n='1'></$c>
                <$c n='9'>
                    <div className="table-container ">
                        <table className="table is-fullwidth is-bordered is-striped is-narrow is-hoverable ">
                            <thead>
                                <tr>
                                    <th style={{ width: '16rem' }}>Required Documents</th>
                                    <th>Document Uploaded</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    bookingsStore.current?.commercialInvoice_filename_list?.length > 0 &&  (<tr>
                                        <td>Commercial Invoice (Non-Mandatory)</td>
                                        <td>{ brec.commercialInvoice_filename_list.map(renderLink).map(link => <>{link}<br/></>)}</td>
                                    </tr>)
                                }   
                                   {
                                        bookingsStore.current?.packingList_filename_list?.length > 0  &&
                                            <tr>
                                                <td>Packing List (Non-Mandatory)</td>
                                                <td>{brec.packingList_filename_list.map(renderLink).map(link => <>{link}<br/></>)}</td>
                                                </tr>  
                                    }
                                {
                                    (
                                        (brec.commodityType && brec.commodityType.startsWith('Non-Hazardous Chemicals'))
                                        || (brec.contract.hazardous === 'Yes')
                                    ) ?
                                        <tr>
                                            <td>MSDS</td>
                                            <td>{renderLink(brec.msds_filename)}</td>
                                        </tr>
                                        : <></>
                                }
                                {
                                    (brec.commodityType && brec.commodityType.startsWith('Non-Hazardous Chemicals')) ?
                                        <tr>
                                            <td>Non-DG Declaration</td>
                                            <td>{renderLink(brec.nondgdecl_filename)}</td>
                                        </tr> : <></>
                                }
                                {
                                    (brec.contract.hazardous === 'Yes') ?
                                        <>
                                            <tr>
                                                <td>DG Declaration</td>
                                                <td>{renderLink(brec.dgdecl_filename)}</td>
                                            </tr>
                                            <tr>
                                                <td>IIP Certificate</td>
                                                <td>{renderLink(brec.iipcert_filename)}</td>
                                            </tr>
                                        </> : <></>
                                }
                            </tbody>
                        </table>
                    </div>
                </$c>
            </div>

        </div>
    )
}

export function CarrierWebView2v3(props) {
    console.log('INSIDE CARRIER WEBVIEW', props.showbooking)
    const [space, setSpace] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [branchName, setBranchName] = useState('')
    const [merchantID, setMerchantID] = useState('')
    const [fbaDetails, setFbaDetails] = useState([])
    const [fbaupdatecheck, setFbaupdatecheck] = useState(1)
    const [cecOc, setCecOc] = useState(null)
    const [cecOd, setCecOd] = useState(null)
    const [shipperSearch, setShipperSearch] = useState('')
    const [shipperDetails, setShipperDetails] = useState({ _id: "", firstName: "", lastName: "", emailId: "", mobileNumber: "", companyName: "", address: "", pincode: "", country: "", role: "", city: "", state: "", newShipper: false })
    useSnapshot(loginStore)
    useSnapshot(bookingsStore)
    useSnapshot(contractsStore)
    useSnapshot(configStore)
    useSnapshot(entityStore)
    useSnapshot(taskStore)
    let brec = bookingsStore.current
    let task = taskStore.current

    useEffect(() => {
        if(brec.contract){
        setCecOc(brec.country === (brec.contract.shipmentType.toLowerCase().includes('air') ?
        Utils.extractCountry(brec.contract.originAirport)
        : Utils.extractCountry(brec.contract.portOfReceipt)));
        setCecOd(brec.country === Utils.extractCountry(brec.contract?.finalPlaceOfDelivery));
        task["newShipper"] = false
        const fetchShipperData = async () => {
            if (brec.contract?.shipperConsigneeId) {
                try {
                    const shipperData = await ShipperApi.getShipperById(brec.contract.shipperConsigneeId);
                    setShipperDetails(shipperData.data[0]);
                    setShipperSearch(shipperData.data[0].companyName);
                } catch (error) {
                    console.error(error);
                }
            }

        };
        if (cecOc) { task["expName"] = brec.company }
        if (cecOd) { task["impName"] = brec.company }
        cecOc ? task['role'] = "imp" : task['role'] = "exp"

        fetchShipperData();
        }
    }, [brec.contract?.shipperConsigneeId, cecOc, cecOd, brec.contract, brec.country, task, brec.company]);

    useEffect(()=>{
        if(merchantID!=brec.merchantID){
            setMerchantID(brec.merchantID)
        }
        if(JSON.stringify(fbaDetails)!=JSON.stringify(brec.fbaDetails)){
            setFbaDetails(brec.fbaDetails)
        }
    },[brec])

    useEffect(()=>{
        if(fbaupdatecheck!=1){
            let l = false
            if(brec.merchantID!=merchantID){
                l = true
            }
            if(JSON.stringify(fbaDetails)!=JSON.stringify(brec.fbaDetails)){
                l = true
            }
            if(l){
                BookingsApi.setfbadetailsBooking(bookingsStore.current._id,fbaDetails,merchantID)
                brec.fbaDetails = JSON.parse(JSON.stringify(fbaDetails))
            }
        }
    },[fbaupdatecheck])

    const aguser = loginStore.userRec.aguserRec
    useEffect(() => {
        // EntityApi.getList()
        // const ent = EntityV2Store.list.filter(e1 => e1.entityId === brec.entityId)
        if (loginStore.isClient()) {
            EntityV2Api.viewEntity(brec.entityId, (data) => {
                if (data) {
                    let entity = data
                    setCompanyName(brec.company)
                    BranchApi.viewBranch(brec.branchId, (data) => {
                        if (data.data) {
                            setBranchName(`${data.data.state?.split("/")[0]} - ${(data.data.gst.length > 0 ? data.data.gst : data.data.tinNum)}`)
                        }
                    })
                    setSpace(space === '' ? ' ' : '')

                } else {
                    setCompanyName(brec.company)
                }
            })
        } else {
            EntityApi.getList()
            const ent = EntityV2Store.list.filter(e1 => e1.entityId === brec.entityId)
            if (ent.length > 0) {
                let entity = ent[0]
                setCompanyName(brec.company)
                BranchApi.viewBranch(brec.branchId, (data) => {
                    if (data.data) {
                        setBranchName(`${data.data.state?.split("/")[0]} - ${(data.data.gst.length > 0 ? data.data.gst : data.data.tinNum)}`)
                    }
                })
                setSpace(space === '' ? ' ' : '')
            } else {
                setCompanyName(brec.company)
            }
        }

    }, [bookingsStore.current])
    if (!brec) return <></>
    if (!brec.contract) return <></>
    console.log('GOT HERE ############################', brec.contract._id, brec)
    contractsStore.current = brec.contract

    const renderLink = (fileName) => {
        if (fileName && fileName.length > 3) {
            return (
                <a href={process.env.REACT_APP_API_SERVER + '/clientfiles/' + fileName} target="_blank">{fileName}</a>
            )
        } else return <></>
    }

    let commList = commodityList
    if (brec.contract.hazardous === 'Yes') commList = hazardousList
    const stype = () => brec.contract.shipmentType.toUpperCase().substr(0, 3)
    console.log('==============FROM INSIDE CARRIERWEBVIEW', brec.contract.shipmentType.toUpperCase().substr(0, 3))
    let showbooking = {}
    if (props.showbooking === '1') showbooking = { showbooking: '1' }
    let Lclschedulecomponent = LCLScheduleCompv3
    let Airschedulecomponent = AirScheduleCompv3
    if (bookingsStore.current.createdFromPricing) {
        if (stype() == "LCL") {
            Lclschedulecomponent = LCLPricingSchedule
        }
        if (stype() == "AIR") {
            Airschedulecomponent = AirPricingSchedule
        }
    }
    console.log('\n\n\nfrompricing=', bookingsStore.current.createdFromPricing, '***\n\n')
    if(brec.fba==undefined){
        brec.fba = 'No'
    }
    return (
        <div style={{ backgroundColor: "white", fontSize: '0.8rem' }}>
            {
                bookingsStore.current?.hasToShowDummySchedule ? <DummySchedulev3 stype={stype()} viewonly={true} />
                    : stype() === 'LCL' ?
                        <Lclschedulecomponent data={bookingsStore.current.schedule} viewonly={true} {...showbooking} nobutton={true} /> :
                        stype() === 'FCL' ?
                            <FCLScheduleCompv3 data={bookingsStore.current.schedule} viewonly={true} {...showbooking} /> :
                            <Airschedulecomponent viewonly={true} {...showbooking} />
            }
            <div>
            <div>
                <h2 style={{ paddingTop: "10px", fontSize: "1rem", fontWeight: "bold" }}>Shipper / Exporter details {cecOc && ' - invoicing'}</h2>

                <div className="columns">
                    <$cr n='2'>First Name{space}</$cr>
                    <$c n='3'>
                        {cecOc ? brec.firstName : shipperDetails.firstName}
                    </$c>
                    <$cr n='2'>Last Name</$cr>
                    <$c n='3'>
                        {cecOc ? brec.lastName : shipperDetails.lastName}
                    </$c>
                </div>

                <div className="columns">
                    <$cr n='2'>Email ID</$cr>
                    <$c n='3'>
                        {cecOc ? brec.emailid : shipperDetails.emailId}
                    </$c>
                    <$cr n='2'>Contact Number</$cr>
                    <$c n='3'>
                        {cecOc ? brec.phone : shipperDetails.mobileNumber}
                    </$c>
                </div>
                {cecOc ?
                    <div className="columns">
                        <$cr n='2'>Branch</$cr>
                        <$c n='3'>
                            {/* {brec.branchId} */}
                            {branchName}
                        </$c>
                    </div> : null}
                <div className="columns">
                    <$cr n='2'>Shipper Name</$cr>
                    <$c n='3'>
                        {cecOc ? companyName : shipperDetails.companyName}
                    </$c>
                    <$cr n='2'>Address</$cr>
                    <$c n='3'>
                        {cecOc ? brec.address : shipperDetails.address}
                    </$c>
                </div>
                {!cecOc &&
                <div>
                <div className="columns">
                 <$cr n='2'>Send Milestone alerts ?</$cr>
                    <$c n='3'>
                        <input className={`checkbox`} type = 'checkbox' checked={brec.sendMilestoneAlert}
                        />
                    </$c>
                </div>
                <div className="columns is-narrow">
                    <$cr n='2'>City</$cr>
                    <$c n='3'>
                        {shipperDetails.city}
                    </$c>
                    <$cr n='2'>State</$cr>
                    <$c n='3'>
                        {shipperDetails.state}
                    </$c>
                </div></div>
}
                <div className="columns is-narrow">
                    <$cr n='2'>PIN/ZIP Code</$cr>
                    <$c n='3'>
                        {cecOc ? brec.pin : shipperDetails.pincode}
                    </$c>
                    <$cr n='2'>Country</$cr>
                    <$c n='3'>
                        {cecOc ? brec.country : shipperDetails.country}
                    </$c>
                </div>
                </div>
                {!(!cecOc && !cecOd) ?
                (!(cecOc && !shipperDetails.companyName) &&
                <div>
                    <h2 style={{ paddingTop: "10px", fontSize: "1rem", fontWeight: "bold" }}>Consignee / Importer details {cecOd && ' - invoicing'}</h2>
                    <div className="columns">
                        <$cr n='2'>First Name{space}</$cr>
                        <$c n='3'>
                            {cecOd ? brec.firstName : shipperDetails.firstName}
                        </$c>
                        <$cr n='2'>Last Name</$cr>
                        <$c n='3'>
                            {cecOd ? brec.lastName : shipperDetails.lastName}
                        </$c>
                    </div>

                    <div className="columns">
                        <$cr n='2'>Email ID</$cr>
                        <$c n='3'>
                            {cecOd ? brec.emailid : shipperDetails.emailId}
                        </$c>
                        <$cr n='2'>Contact Number</$cr>
                        <$c n='3'>
                            {cecOd ? brec.phone : shipperDetails.mobileNumber}
                        </$c>
                    </div>
                    {cecOd ?
                        <div className="columns">
                            <$cr n='2'>Branch</$cr>
                            <$c n='3'>
                                {/* {brec.branchId} */}
                                {branchName}
                            </$c>
                        </div> : null}
                    <div className="columns">
                        <$cr n='2'>Consignee Name</$cr>
                        <$c n='3'>
                            {cecOd ? companyName : shipperDetails.companyName}
                        </$c>
                        <$cr n='2'>Address</$cr>
                        <$c n='3'>
                            {cecOd ? brec.address : shipperDetails.address}
                        </$c>
                    </div>
                    {!cecOd &&
                    <div>
                    <div className="columns">
                     <$cr n='2'>Send Milestone alerts ?</$cr>
                        <$c n='3'>
                            <input className={`checkbox`} type = 'checkbox' checked={brec.sendMilestoneAlert}
                            />
                        </$c>
                    </div>
                <div className="columns is-narrow">
                    <$cr n='2'>City</$cr>
                    <$c n='3'>
                        {shipperDetails.city}
                    </$c>
                    <$cr n='2'>State</$cr>
                    <$c n='3'>
                        {shipperDetails.state}
                    </$c>
                </div></div>}
                    <div className="columns is-narrow">
                        <$cr n='2'>PIN/ZIP Code</$cr>
                        <$c n='3'>
                            {cecOd ? brec.pin : shipperDetails.pincode}
                        </$c>
                        <$cr n='2'>Country</$cr>
                        <$c n='3'>
                            {cecOd ? brec.country : shipperDetails.country}
                        </$c>
                    </div>
                </div>)
                : <div>
                <h2 style={{ paddingTop: "10px", fontSize: "1rem", fontWeight: "bold" }}>Invoice details</h2>
                {cecOd ?
                    <div className="columns">
                        <$cr n='2'>Branch</$cr>
                        <$c n='3'>
                            {/* {brec.branchId} */}
                            {branchName}
                        </$c>
                    </div> : null}
                <div className="columns">
                    <$cr n='2'>Shipper Name</$cr>
                    <$c n='3'>
                        {companyName}
                    </$c>
                    <$cr n='2'>Address</$cr>
                    <$c n='3'>
                        {brec.address}
                    </$c>
                </div>
                <div className="columns is-narrow">
                    <$cr n='2'>PIN/ZIP Code</$cr>
                    <$c n='3'>
                        {brec.pin}
                    </$c>
                    <$cr n='2'>Country</$cr>
                    <$c n='3'>
                        {brec.country}
                    </$c>
                </div>
            </div>}
            </div>
            <div>
                  <h2 style={{ paddingTop: "10px", fontSize: "1rem", fontWeight: "bold" }}>Shipment details</h2>
            <div className="columns">
                <$cr n='2'>Incoterms</$cr>
                <$c n='3'>
                    {brec.incoTerms}
                </$c>
                <$cr n='2'>Commodity Type</$cr>
                <$c n='3'>
                    {brec.commodityType}
                </$c>
            </div>
            {/* <div className="columns is-narrow">
                    <$cr n='2'>FBA</$cr>
                    <$c n='2'>
                        <Agyesno disabled={true} label='' record={brec} name='fba' />
                    </$c>
                    <$c n='8'>
                    </$c>
                </div> */}
            {
                (brec.fba=='Yes' && fbaDetails!=undefined && fbaDetails.length>0 && fbaDetails[0].fbaID!=undefined)? <>
                <h2 style={{ paddingTop: "10px", fontSize: "1rem", fontWeight: "bold" }}>FBA Details</h2>
                <div className="columns is-narrow">    
                        <$cr n='2'>Merchant ID</$cr>
                        <$c n='3'>
                            <input className={`input is-small`} value={merchantID}
                                onChange={(e)=>{
                                    setMerchantID(Utils.removeSpecialCharacters(e.target.value.trim(),14))
                                }}
                                onBlur={()=>{setFbaupdatecheck(fbaupdatecheck+1)}}
                            />
                        </$c>
                    </div>
                    {
                        brec.fbaDetails.map((e,i)=>{
                            return <>
                            {
                                brec.fbaDetails.length>1?<div className="columns is-narrow">    
                                <$cr n='2'><h2 style={{ paddingTop: "10px", fontSize: "0.7rem", fontWeight: "bold" }}>Destination {i+1}</h2></$cr>
                            </div>:""
                            }
                            <div className="columns is-narrow">   
                        <$cr n='2'>FBA ID</$cr>
                        <$c n='3'>
                            <p class="control has-icons-left">
                                <input class="input is-small" className={`input is-small`} value={fbaDetails[i].fbaID}
                                    onChange={(e)=>{
                                        let f = fbaDetails
                                        f[i].fbaID=Utils.removeSpecialCharacters(e.target.value.trim(),12)
                                        setFbaDetails(JSON.parse(JSON.stringify(f)))
                                    }}
                                    onBlur={()=>{setFbaupdatecheck(fbaupdatecheck+1)}}
                                />
                                <span class="icon is-small is-left">
                                FBA
                                </span>
                            </p>
                        </$c>
                        <$cr n='2'>Amazon Reference ID</$cr>
                        <$c n='3'>
                            <input className={`input is-small`} value={fbaDetails[i].purchaseOrderID}
                                onChange={(e)=>{
                                    let f = fbaDetails
                                    f[i].purchaseOrderID=Utils.removeSpecialCharacters(e.target.value.trim(),8)
                                    setFbaDetails(JSON.parse(JSON.stringify(f)))
                                }}
                                onBlur={()=>{setFbaupdatecheck(fbaupdatecheck+1)}}
                                //onBlur={BookingsApi.setcustrefBooking(bookingsStore.current._id,brec.fbaDetails,brec.merchantID)}
                            />
                        </$c>
                        <$cr n='2'></$cr>
                        <$c n='3'>
                        </$c>
                    </div>
                            </>
                        })
                    }
                </>:<></>
            }
            <div className="columns is-narrow">
                <$cr n='2'>Special Instructions</$cr>
                <$c n='6'>
                    {brec.specialInstructions}
                </$c>
                <$c n='2'>
                    {
                        // (enableMBL().disabled)
                        (contractsStore.current.shipmentType.toLowerCase().includes('fcl')
                            && contractsStore.current.shipmentScope.endsWith('ort')) ?
                            <Agyesno disabled={true} label='Do you require a Direct Carrier MBL?'
                                record={brec} name='carrierMBL' /> : null
                    }
                </$c>
            </div>
            </div>

            <div className="columns">
                <$c n='1'></$c>
                <$c n='9'>
                    <div className="table-container ">
                        <table className="table is-fullwidth is-bordered is-striped is-narrow is-hoverable ">
                            <thead>
                                <tr>
                                    <th style={{ width: '16rem' }}>Required Documents</th>
                                    <th>Document Uploaded</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    bookingsStore.current?.commercialInvoice_filename_list?.length > 0 && (<tr>
                                        <td>Commercial Invoice (Non-Mandatory)</td>
                                        <td>{brec.commercialInvoice_filename_list.map(renderLink).map(link => <>{link}<br /></>)}</td>
                                    </tr>)
                                }
                                {
                                    bookingsStore.current?.packingList_filename_list?.length > 0 &&
                                    <tr>
                                        <td>Packing List (Non-Mandatory)</td>
                                        <td>{brec.packingList_filename_list.map(renderLink).map(link => <>{link}<br /></>)}</td>
                                    </tr>
                                }
                                {
                                    bookingsStore.current?.specialInstructions_filename_list?.length > 0 &&
                                    <tr>
                                        <td>Special Instructions (Non-Mandatory)</td>
                                        <td>{brec.specialInstructions_filename_list.map(renderLink).map(link => <>{link}<br /></>)}</td>
                                    </tr>
                                }
                                {
                                    (
                                        (brec.commodityType && brec.commodityType.startsWith('Non-Hazardous Chemicals'))
                                        || (brec.contract.hazardous === 'Yes')
                                    ) ?
                                        <tr>
                                            <td>MSDS</td>
                                            <td>{renderLink(brec.msds_filename)}</td>
                                        </tr>
                                        : <></>
                                }
                                {
                                    (brec.commodityType && brec.commodityType.startsWith('Non-Hazardous Chemicals')) ?
                                        <tr>
                                            <td>Non-DG Declaration</td>
                                            <td>{renderLink(brec.nondgdecl_filename)}</td>
                                        </tr> : <></>
                                }
                                {
                                    (brec.contract.hazardous === 'Yes') ?
                                        <>
                                            <tr>
                                                <td>DG Declaration</td>
                                                <td>{renderLink(brec.dgdecl_filename)}</td>
                                            </tr>
                                            <tr>
                                                <td>IIP Certificate</td>
                                                <td>{renderLink(brec.iipcert_filename)}</td>
                                            </tr>
                                        </> : <></>
                                }
                            </tbody>
                        </table>
                    </div>
                </$c>
            </div>

        </div>
    )
}

