import { useSnapshot } from "valtio";
import { myactionsstore } from "./store-myactions";
import { TsUnloc } from "../components/AgComponents";
import { bookingsStore, configStore } from "../state/CommonState";
import { useEffect, useState } from "react";
import { OneDatetable,  OneInput5table } from "../mytasks/act-common";
import { dsrstatic_FCLDockDock_keys, dsrstatic_FCLDockFactory_keys, dsrstatic_FCLFactoryDock_keys, dsrstatic_FCLFactoryFactory_keys, dsrstatic_cn } from "./dsr_static";

export function CnModal(props){
useSnapshot(myactionsstore)
useSnapshot(configStore)

let ret = configStore.modalvisibledata
let st = ret.shipmentType
let sc = ret.shipmentScope
let por = ret.por
let pol = ret.pol
let pod = ret.pod
let fpod = ret.fpod
let containerlist = myactionsstore.selectcontainer
let isCargoPicked = true

if (props?.vehicleDetails){
    isCargoPicked = ret?.vorgin?.some(origin => origin.cargo_pickup);
}

if(ret.containerlist!=undefined && ret.containerlist.length>0){
    myactionsstore.selectcontainer = ret.containerlist
}else{
    if(myactionsstore.selectcontainer.length==0){
        let a = ret?.booking?.contract?.containerList
        let l = []
        if(a){
            for (let i = 0; i < a.length; i++) {
                for (let j = 0; j < Number(a[i]["numContainers"]); j++) {
                    l.push({"container_types":a[i]["containerType"]})
                }
            }
            if(l.length>0){
            myactionsstore.selectcontainer = l
            }
        }
    }
    
}

let keys = []
let obj = dsrstatic_cn
let arr = ["gatein_pol","gatein_fpod","container_pickup_fpod","empty_return"]

if(sc == "Port-to-Door" || sc =="Door-to-Door"){
    arr.push("estimate_delivery_date_to_consignee")
    arr.push("container_delivery")
    arr.push("container_arrival_at_fpod")
    arr.push("container_de_vanning")
}
if(sc == "Door-to-Port" || sc =="Door-to-Door"){
    arr.push("empty_container_pickup")
    arr.push("container_arrival_factory")
    arr.push("loaded_and_dispatch")
    arr.push("stuffing_confirmation")
}
if(por != pol){
    arr.push("container_handover")
    arr.push("por_to_pol_movement")
}
if(pod != fpod){
    arr.push("gateout_to_fpod")
}

if("FCL (Factory/Factory)"==st){
    keys = dsrstatic_FCLFactoryFactory_keys
}

if("FCL (Dock/Factory)"==st){
    keys = dsrstatic_FCLDockFactory_keys
}

if("FCL (Factory/Dock)"==st){
    keys = dsrstatic_FCLFactoryDock_keys
}
if("FCL (Dock/Dock)"==st){
    keys = dsrstatic_FCLDockDock_keys
}




const onsubmit1 = () =>{
    myactionsstore.selectcontainer = containerlist
    if(myactionsstore.error.length>0){
        myactionsstore.error = []
    }
    let k = 0
    for (let i = 0; i < containerlist.length; i++) {
        if(containerlist[i]['container_number']){
            containerlist[i]['container_number'] = containerlist[i]['container_number'].toUpperCase()
            if(!(/^[A-Z]{1,4}[0-9]{1,7}/.test(containerlist[i]['container_number'])) || containerlist[i]['container_number'].length!=11){
                k = 1
                myactionsstore.error.push('container_number'+i)
            }
        }
    }
    if(k==0){
        ret.containerlist = containerlist
        console.log(ret)
        configStore.modalvisibledata = ret
        myactionsstore.cnModalVisible = false
    }
}


let modalClass = 'modal'
    if (myactionsstore.cnModalVisible) modalClass += ' is-active'
    return (
        <>
            {myactionsstore.cnModalVisible ? (
                <div
                    className={modalClass}
                    onClick={() => {
                        myactionsstore.cnModalVisible = false;
                    }}
                >
                    <div className="modal-background"></div>
                    <div
                        className="modal-card"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        style={{ borderRadius: '10px', width: '90%' }}
                    >
                        <section className="modal-card-body">
    
                            <div className="table-container" onClick={(e) => e.stopPropagation()}>
                                <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                                    <thead>
                                        <tr>
                                            <th>{obj['container_number']}</th>
                                            <th>{obj['container_types']}</th>
                                            {keys.map((e) => {
                                                if (arr.indexOf(e) !== -1) {
                                                    return <th key={e}>{obj[e]}</th>;
                                                }
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {containerlist.map((e, i) => (
                                            <tr key={i}>
                                                <td>
                                                    <OneInput5table
                                                        name="container_number"
                                                        record={e}
                                                        label={obj['container_number']}
                                                        ind={i}
                                                    />
                                                </td>
                                                <td>{e['container_types']}</td>
                                                {keys.map((a) => {
                                                    if (arr.indexOf(a) !== -1) {
                                                        const isDisabled =
                                                            (a === 'gatein_pol' || a === 'arrival_at_por') &&
                                                            (!isCargoPicked || !ret.booking.erpJobId);
    
                                                        return (
                                                            <td key={a}>
                                                                <OneDatetable
                                                                    name={a}
                                                                    record={e}
                                                                    label={obj[a]}
                                                                    disabled={isDisabled}
                                                                />
                                                            </td>
                                                        );
                                                    }
                                                })}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            {!isCargoPicked && (
                                <p className="has-text-danger">
                                    Cargo must be picked up for Gate in @ POL / Arrival @ POR
                                </p>
                            )}
                            {!ret.booking.erpJobId && (
                                <p className="has-text-danger">
                                    ERP Job ID is required for Gate in @ POL / Arrival @ POR
                                </p>
                            )}
    
                            <button
                                className="button is-warning"
                                onClick={() => (myactionsstore.cnModalVisible = false)}
                            >
                                Cancel
                            </button>
                            <button
                                className="button is-danger"
                                style={{ float: 'right' }}
                                onClick={onsubmit1}
                            >
                                Submit
                            </button>
                        </section>
                    </div>
                </div>
            ) : null}
        </>
    );
    
}