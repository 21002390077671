const dsrstatic_cn=
{
    "container_number":"Container Number(s)",
    "container_types":"Container Types",
    "empty_container_pickup":"Empty Container Pick-up",
        "container_arrival_factory":"Empty Container Arrival at Factory",
        "loaded_and_dispatch":"Loaded & Dispatched",
        "container_handover":"Container Handover @ POR",
        "por_to_pol_movement":"Rail-out / Truck-out Status",
        "gatein_pol":"Gate-in @ POL",
        "gateout_to_fpod":"ATD from POD",
        "gatein_fpod":"ATA @ FPOD",
        "container_pickup_fpod":"Container Pick-up @ FPOD",
        "estimate_delivery_date_to_consignee":"Estimated Delivery Date to Consignee",
        "container_delivery":"Container Delivered to Consignee",
        "empty_return":"Empty Container Return to Carrier",
        "empty_container_arrival_at_por":"Container Arrival @ Origin CFS",
        "stuffing_confirmation":"CFS Stuffing Date",
        "container_arrival_at_fpod":"Container Arrival @ Destination CFS",
        "container_de_vanning":"Container De-vanning"
}


const dsrstatic_FCLFactoryFactory={
"shipper_name":"Shipper Name",
"branch_name":"Branch Name",
"consignee_name":"Consignee Name",
"cust_ref_number":"Customer Reference #",
"agraga_booking_#":"Agraga Booking Reference #",
"origin":"Origin",
"destination":"Destination",
"por":"Place of Receipt",
"pol":"Port of Loading",
"pod":"Port of Discharge",
"fpod":"Final Place of Delivery",
"mlo":"Mainline Operator",
"carrier_booking_ref_number":"Carrier Booking Reference #",
"carrier_booking_release":"Carrier Release Order",
"vgm_cut_off":"VGM Cut off",
"shipping_instructions_cut_off":"SI Cut-off",
"gate_cut_off":"Gate Cut off",
"fl_vessel_name":"1st Leg Vessel Name",
"fl_vessel_number":"1st Leg Voyage #",
'cargo_gatein_CFS': 'Cargo Gate-in at CFS',
"etd_at_pol":"ETD @ POL",
"sob_pol":"Shipped-on-Board",
"hbl_number":"HBL #",
"mbl_number":"MBL #",
"arrival_notice":"Arrival Notice to Consignee",
"eta_fpod":"ETA @ FPOD",
"last_free_date_at_fpod":"Last Free Date @ FPOD",
"delivery_order_release":"Delivery Order Release",
"remarks":"Special Remarks",
"status_closed": "Is the Booking Closed?",
}


const dsrstatic_FCLFactoryFactory_keys=[
"shipper_name",
"branch_name",
"consignee_name",
"cust_ref_number",
"agraga_booking_#",
"origin",
"destination",
"destination2",
"destination3",
"destination4",
"destination5",
"por",
"pol",
"pod",
"fpod",
"mlo",
"carrier_booking_ref_number",
"carrier_booking_release",
"vgm_cut_off",
"shipping_instructions_cut_off",
"gate_cut_off",
"empty_container_pickup",
"container_arrival_factory",
"loaded_and_dispatch",
"container_number",
"container_handover",
"por_to_pol_movement",
"gatein_pol",
"fl_vessel_name",
"fl_vessel_number",
'cargo_gatein_CFS',
'etd_at_pol',
"sob_pol",
"hbl_number",
"mbl_number",
"ts1_port",
'ts1_arrival_estimate',
"ts1_arrival",
'ts1_departure_estimate',
"ts1_departure",
"ts1_vessel_name",
"ts1_vessel_number",
"ts2_port",
'ts2_arrival_estimate',
"ts2_arrival",
'ts2_departure_estimate',
"ts2_departure",
"ts2_vessel_name",
"ts2_vessel_number",
"ts3_port",
'ts3_arrival_estimate',
"ts3_arrival",
'ts3_departure_estimate',
"ts3_departure",
"ts3_vessel_name",
"ts3_vessel_number",
"ts4_port",
'ts4_arrival_estimate',
"ts4_arrival",
'ts4_departure_estimate',
"ts4_departure",
"ts4_vessel_name",
"ts4_vessel_number",
"arrival_notice",
'eta_at_pod',
"pod_arrival",
"etd_pod",
"gateout_to_fpod",
"eta_fpod",
"gatein_fpod",
"last_free_date_at_fpod",
"delivery_order_release",
"container_pickup_fpod",
"estimate_delivery_date_to_consignee",
"container_delivery",
"empty_return",
"remarks",
"status_closed",
"containerlist",
"vorgin",
"vdes",
"vdes2",
"vdes3",
"vdes4",
"vdes5",
"_id",
"tsUpdatedOn",
"tsUpdatedBy",
"project44_1d",
"total_package"
]


const dsrstatic_FCLDockFactory =
{
"shipper_name":"Shipper Name",
"branch_name":"Branch Name",
"consignee_name":"Consignee Name", 
"cust_ref_number":"Customer Reference #",
"agraga_booking_#":"Agraga Booking Reference #",
"origin":"Origin",
"destination":"Destination",
"por":"Place of Receipt",
"pol":"Port of Loading",
"pod":"Port of Discharge",
"fpod":"Final Place of Delivery",
"mlo":"Mainline Operator",
"carrier_booking_ref_number":"Carrier Booking Reference #",
"carrier_booking_release":"Carrier Release Order",
"vgm_cut_off":"VGM Cut off",
"shipping_instructions_cut_off":"SI Cut-off",
"gate_cut_off":"Gate Cut off",
"fl_vessel_name":"1st Leg Vessel Name",
"fl_vessel_number":"1st Leg Voyage #",
"cargo_gatein_CFS": 'Cargo Gate-in at CFS',
"etd_at_pol":"ETD @ POL",
"sob_pol":"Shipped-on-Board",
"hbl_number":"HBL #",
"mbl_number":"MBL #",
"arrival_notice":"Arrival Notice to Consignee",
"eta_fpod":"ETA @ FPOD",
"last_free_date_at_fpod":"Last Free Date @ FPOD",
"delivery_order_release":"Delivery Order Release",
"remarks":"Special Remarks",
"status_closed": "Is the Booking Closed?",
}


const dsrstatic_FCLDockFactory_keys = [ 
"shipper_name",
"branch_name",
"consignee_name",
"cust_ref_number",
"agraga_booking_#",
"origin",
"destination",
"destination2",
"destination3",
"destination4",
"destination5",
"por",
"pol",
"pod",
"fpod",
"mlo",
"carrier_booking_ref_number",
"carrier_booking_release",
"vgm_cut_off",
"shipping_instructions_cut_off",
"gate_cut_off",
"empty_container_pickup",
"empty_container_arrival_at_por",
"container_number",
"stuffing_confirmation",
"container_handover",
"por_to_pol_movement",
"gatein_pol",
"fl_vessel_name",
"fl_vessel_number",
'cargo_gatein_CFS',
'etd_at_pol',
"sob_pol",
"hbl_number",
"mbl_number",
"ts1_port",
'ts1_arrival_estimate',
"ts1_arrival",
'ts1_departure_estimate',
"ts1_departure",
"ts1_vessel_name",
"ts1_vessel_number",
"ts2_port",
'ts2_arrival_estimate',
"ts2_arrival",
'ts2_departure_estimate',
"ts2_departure",
"ts2_vessel_name",
"ts2_vessel_number",
"ts3_port",
'ts3_arrival_estimate',
"ts3_arrival",
'ts3_departure_estimate',
"ts3_departure",
"ts3_vessel_name",
"ts3_vessel_number",
"ts4_port",
'ts4_arrival_estimate',
"ts4_arrival",
'ts4_departure_estimate',
"ts4_departure",
"ts4_vessel_name",
"ts4_vessel_number",
"arrival_notice",
'eta_at_pod',
"pod_arrival",
"etd_pod",
"gateout_to_fpod",
"eta_fpod",
"gatein_fpod",
"last_free_date_at_fpod",
"delivery_order_release",
"container_pickup_fpod",
"estimate_delivery_date_to_consignee",
"container_delivery",
"empty_return",
"remarks",
"status_closed",
"containerlist",
"vorgin",
"vdes",
"vdes2",
"vdes3",
"vdes4",
"vdes5",
"_id",
"tsUpdatedOn",
"tsUpdatedBy",
"project44_1d",
"total_package"
]


const dsrstatic_FCLFactoryDock = {
"shipper_name":"Shipper Name",
"branch_name":"Branch Name",
"consignee_name":"Consignee Name",
"cust_ref_number":"Customer Reference #",
"agraga_booking_#":"Agraga Booking Reference #",
"origin":"Origin",
"destination":"Destination",
"por":"Place of Receipt",
"pol":"Port of Loading",
"pod":"Port of Discharge",
"fpod":"Final Place of Delivery",
"mlo":"Mainline Operator",
"carrier_booking_ref_number":"Carrier Booking Reference #",
"carrier_booking_release":"Carrier Release Order",
"vgm_cut_off":"VGM Cut off",
"shipping_instructions_cut_off":"SI Cut-off",
"gate_cut_off":"Gate Cut off",
"fl_vessel_name":"1st Leg Vessel Name",
"fl_vessel_number":"1st Leg Voyage #",
'cargo_gatein_CFS': 'Cargo Gate-in at CFS',
"etd_at_pol":"ETD @ POL",
"sob_pol":"Shipped-on-Board",
"hbl_number":"HBL #",
"mbl_number":"MBL #",
"arrival_notice":"Arrival Notice to Consignee",
"eta_fpod":"ETA @ FPOD",
"last_free_date_at_fpod":"Last Free Date @ FPOD",
"delivery_order_release":"Delivery Order Release",
"remarks":"Special Remarks",
"status_closed": "Is the Booking Closed?",
}


const dsrstatic_FCLFactoryDock_keys = [
"shipper_name",
"branch_name",
"consignee_name",
"cust_ref_number",
"agraga_booking_#",
"origin",
"destination",
"destination2",
"destination3",
"destination4",
"destination5",
"por",
"pol",
"pod",
"fpod",
"mlo",
"carrier_booking_ref_number",
"carrier_booking_release",
"vgm_cut_off",
"shipping_instructions_cut_off",
"gate_cut_off",
"empty_container_pickup",
"container_arrival_factory",
"loaded_and_dispatch",
"container_number",
"container_handover",
"por_to_pol_movement",
"gatein_pol",
"fl_vessel_name",
"fl_vessel_number",
'cargo_gatein_CFS',
'etd_at_pol',
"sob_pol",
"hbl_number",
"mbl_number",
"ts1_port",
'ts1_arrival_estimate',
"ts1_arrival",
'ts1_departure_estimate',
"ts1_departure",
"ts1_vessel_name",
"ts1_vessel_number",
"ts2_port",
'ts2_arrival_estimate',
"ts2_arrival",
'ts2_departure_estimate',
"ts2_departure",
"ts2_vessel_name",
"ts2_vessel_number",
"ts3_port",
'ts3_arrival_estimate',
"ts3_arrival",
'ts3_departure_estimate',
"ts3_departure",
"ts3_vessel_name",
"ts3_vessel_number",
"ts4_port",
'ts4_arrival_estimate',
"ts4_arrival",
'ts4_departure_estimate',
"ts4_departure",
"ts4_vessel_name",
"ts4_vessel_number",
"arrival_notice",
'eta_at_pod',
"pod_arrival",
"etd_pod",
"gateout_to_fpod",
"eta_fpod",
"gatein_fpod",
"last_free_date_at_fpod",
"delivery_order_release",
"container_pickup_fpod",
"container_arrival_at_fpod",
"container_de_vanning",
"empty_return",
"remarks",
"status_closed",
"containerlist",
"vorgin",
"vdes",
"vdes2",
"vdes3",
"vdes4",
"vdes5",
"_id",
"tsUpdatedOn",
"tsUpdatedBy",
"project44_1d",
"total_package"
]

const dsrstatic_FCLDockDock = {
"shipper_name":"Shipper Name",
"branch_name":"Branch Name",
"consignee_name":"Consignee Name", 
"cust_ref_number":"Customer Reference #",
"agraga_booking_#":"Agraga Booking Reference #",
"origin":"Origin",
"destination":"Destination",
"por":"Place of Receipt",
"pol":"Port of Loading",
"pod":"Port of Discharge",
"fpod":"Final Place of Delivery",
"mlo":"Mainline Operator",
"carrier_booking_ref_number":"Carrier Booking Reference #",
"carrier_booking_release":"Carrier Release Order",
"vgm_cut_off":"VGM Cut off",
"shipping_instructions_cut_off":"SI Cut-off",
"gate_cut_off":"Gate Cut off",
"fl_vessel_name":"1st Leg Vessel Name",
"fl_vessel_number":"1st Leg Voyage #",
"cargo_gatein_CFS": "Cargo Gate-in at CFS",
"etd_at_pol":"ETD @ POL",
"sob_pol":"Shipped-on-Board",
"hbl_number":"HBL #",
"mbl_number":"MBL #",
"arrival_notice":"Arrival Notice to Consignee",
"eta_fpod":"ETA @ FPOD",
"last_free_date_at_fpod":"Last Free Date @ FPOD",
"delivery_order_release":"Delivery Order Release",
"remarks":"Special Remarks",
"status_closed": "Is the Booking Closed?",
}


const dsrstatic_FCLDockDock_keys = [
"shipper_name",
"branch_name",
"consignee_name",
"cust_ref_number",
"agraga_booking_#",
"origin",
"destination",
"destination2",
"destination3",
"destination4",
"destination5",
"por",
"pol",
"pod",
"fpod",
"mlo",
"carrier_booking_ref_number",
"carrier_booking_release",
"vgm_cut_off",
"shipping_instructions_cut_off",
"gate_cut_off",
"empty_container_pickup",
"empty_container_arrival_at_por",
"container_number",
"stuffing_confirmation",
"container_handover",
"por_to_pol_movement",
"gatein_pol",
"fl_vessel_name",
"fl_vessel_number",
'cargo_gatein_CFS',
'etd_at_pol',
"sob_pol",
"hbl_number",
"mbl_number",
"ts1_port",
'ts1_arrival_estimate',
"ts1_arrival",
'ts1_departure_estimate',
"ts1_departure",
"ts1_vessel_name",
"ts1_vessel_number",
"ts2_port",
'ts2_arrival_estimate',
"ts2_arrival",
'ts2_departure_estimate',
"ts2_departure",
"ts2_vessel_name",
"ts2_vessel_number",
"ts3_port",
'ts3_arrival_estimate',
"ts3_arrival",
'ts3_departure_estimate',
"ts3_departure",
"ts3_vessel_name",
"ts3_vessel_number",
"ts4_port",
'ts4_arrival_estimate',
"ts4_arrival",
'ts4_departure_estimate',
"ts4_departure",
"ts4_vessel_name",
"ts4_vessel_number",
"arrival_notice",
'eta_at_pod',
"pod_arrival",
"etd_pod",
"gateout_to_fpod",
"eta_fpod",
"gatein_fpod",
"last_free_date_at_fpod",
"delivery_order_release",
"container_pickup_fpod",
"container_arrival_at_fpod",
"container_de_vanning",
"empty_return",
"remarks",
"status_closed",
"containerlist",
"vorgin",
"vdes",
"vdes2",
"vdes3",
"vdes4",
"vdes5",
"_id",
"tsUpdatedOn",
"tsUpdatedBy",
"project44_1d",
"total_package"
]

const dsrstatic_LCL ={
"shipper_name":"Shipper Name",
"branch_name":"Branch Name",
"consignee_name":"Consignee Name",
'cust_ref_number':'Customer Reference #',
'agraga_booking_#':'Agraga Booking Reference #',
'origin':'Origin',
'destination':'Destination',
'carrier':'Carrier',
'cargo_gatein_CFS':'Cargo Gate-in at CFS',
'stuffing_confirmation':'Stuffing Confirmation',
'por':'Place of Receipt',
'pol':'Port of Loading',
"gatein_pol":"Gate-in at POL",
'pol_container_number':'POL Container #',
'hbl_number':'HBL Number',
'mbl_number':'MBL Number',
"etd_at_pol":"ETD @ POL",
'sob_pol':'Shipped-on-Board',
'gatein_reworking':'Container Gate-in at Re-working Port',
'new_carrier_name':'New Carrier Name',
'new_carrier_mbl_number':'New Carrier MBL Number',
'pod':'Port of Discharge (POD)',
'fpod':'Final Place of Delivery (FPOD)',
'eta_fpod':'ETA @ FPOD',
'gatein_fpod':'ATA @ FPOD',
'destuffing_fpod':'De-stuffing Status @ fpod',
"last_free_date_at_fpod":"Last Free Date @ FPOD",
'arrival_notice':'Arrival Notice to Consignee',
'delivery_order_release':'Delivery Order Release to Consignee',
"consolidator":"Consolidator",
'remarks':'Special Remarks',      
"status_closed": "Is the Booking Closed?",
}

const dsrstatic_LCL_keys=[

'shipper_name',
'branch_name',
'consignee_name',
'cust_ref_number',
'agraga_booking_#',
'origin',
'destination',
"destination2",
"destination3",
"destination4",
"destination5",
"por",
"pol",
"pod",
"fpod",
'carrier',
"consolidator",
'cargo_gatein_CFS',
'stuffing_confirmation' ,
'container_handover' ,
'por_to_pol_movement' ,
'pol_arrival' ,
'rail_yard_to_cfs' ,
'destuffing_at_pol' ,
'restuffing_at_pol' ,
'gatein_pol' ,
'pol_container_number',
'hbl_number',
'mbl_number',
'etd_at_pol' ,
'sob_pol' ,
'reworking_pol_port',
'reworking_pol_arrival_estimate',
                        "reworking_pol_arrival",
                        'reworking_pol_departure_estimate',
                        "reworking_pol_departure",
                        "reworking_pol_vessel_name",
                         "reworking_pol_vessel_number",
                         'reworking_pol_destuffing',
                        "reworking_pol_restuffing",
                        "reworking_pol_container_number",
                        
"ts1_port",
'ts1_arrival_estimate',
"ts1_arrival",
'ts1_departure_estimate',
"ts1_departure",
"ts1_vessel_name",
"ts1_vessel_number",
"ts2_port",
'ts2_arrival_estimate',
"ts2_arrival",
'ts2_departure_estimate',
"ts2_departure",
"ts2_vessel_name",
"ts2_vessel_number",
"ts3_port",
'ts3_arrival_estimate',
"ts3_arrival",
'ts3_departure_estimate',
"ts3_departure",
"ts3_vessel_name",
"ts3_vessel_number",
"ts4_port",
'ts4_arrival_estimate',
"ts4_arrival",
'ts4_departure_estimate',
"ts4_departure",
"ts4_vessel_name",
"ts4_vessel_number",
'reworking_pod_port',
'reworking_pod_arrival_estimate',
"reworking_pod_arrival",
'reworking_pod_departure_estimate',
"reworking_pod_departure",

 "reworking_pod_vessel_name",
 "reworking_pod_vessel_number",
 
'reworking_pod_destuffing',
"reworking_pod_restuffing",
"reworking_pod_container_number",
'gatein_reworking' ,
'new_carrier_name',
'new_carrier_mbl_number',
'reworking_container_number',
'reworking_departure_estimate' ,
'sob_reworking' ,
'reworking_departure_actual' ,
'eta_at_pod' ,
'pod_arrival' ,
'etd_pod' ,
'gateout_to_fpod' ,
'eta_fpod' ,
'gatein_fpod' ,
'destuffing_fpod' ,
'last_free_date_at_fpod' ,
'arrival_notice' ,
'delivery_order_release' ,
'cargo_picked_up_by_consignee',
'remarks',
"status_closed",
"vorgin",
"vdes",
"vdes2",
"vdes3",
"vdes4",
"vdes5",
"reworking_at_pol",
"_id",
"tsUpdatedOn",
"tsUpdatedBy",
"project44_1d",
"total_package"
]


const dsrstatic_Air ={
"shipper_name":"Shipper Name",
"branch_name":"Branch Name",
"consignee_name":"Consignee Name",
"cust_ref_number": "Customer Reference #",
"agraga_booking_#": "Agraga Booking Reference #",
"origin": "Origin",
"destination": "Destination",
"departure_airport": "Airport of Departure",
"arrival_airport":"Airport of Arrival",
"airline_booking_release": "Airline Release Order",
"airline_booking_ref_number": "Airline Booking Reference #",
'gatein_at_cargo':'Gate-in at Cargo Terminal',
"etd_airport_of_departure":"ETD Airport of Departure",
"loaded_on_board": "Loaded-on-Board",
"flight_number_departure_airport":"Flight Number from Departure Airport",
"hawb_number": "HAWB Number",
"mawb_number": "MAWB Number",
"ts1_airport": "Transhipment 1 Airport (if any)",
"ts1_arrival_estimate":"ETA Transhipment 1",
"ts1_arrival":"ATA Transhipment 1",
"flight_number_ts1": "Flight Number from Transhipment 1 Airport",
"ts1_departure_estimate":"ETD Transhipment 1",
"ts1_departure":"ATD Transhipment 1",
"ts2_airport": "Transhipment 2 Airport (if any)",
"ts2_arrival_estimate":"ETA Transhipment 2",
"ts2_arrival":"ATA Transhipment 2",
"flight_number_ts2": "Flight Number from Transhipment 2 Airport",
"ts2_departure_estimate":"ETD Transhipment 2",
"ts2_departure":"ATD Transhipment 2",
"eta_at_arrival_airport":"ETA @ Arrival Airport",
"ata_at_arrival_airport":"ATA @ Arrival Airport",
"arrival_notice": "Arrival Notice to Consignee",
"last_free_date_at_fpod":"Last Free Date @ FPOD",
"delivery_order_release": "Delivery Order Released to Consignee",
"remarks": "Special Remarks",
"status_closed": "Is the Booking Closed?",
}


const dsrstatic_Air_keys = [
"shipper_name",
"branch_name",
"consignee_name",
"cust_ref_number",
"agraga_booking_#",
"origin",
"destination",
"destination2",
"destination3",
"destination4",
"destination5",
"departure_airport",
"arrival_airport",
"airline_booking_release",
"airline_booking_ref_number",
'gatein_at_cargo',
"etd_airport_of_departure",
"loaded_on_board",
"flight_number_departure_airport",
"hawb_number",
"mawb_number",
"ts1_airport",
"ts1_arrival_estimate",
"ts1_arrival",
"flight_number_ts1",
"ts1_departure_estimate",
"ts1_departure",
"ts2_airport",
"ts2_arrival_estimate",
"ts2_arrival",
"flight_number_ts2",
"ts2_departure_estimate",
"ts2_departure",
"eta_at_arrival_airport",
"ata_at_arrival_airport",
"arrival_notice",
"last_free_date_at_fpod",
"delivery_order_release",
"remarks",
"status_closed",
"vorgin",
"vdes",
"vdes2",
"vdes3",
"vdes4",
"vdes5",
"_id",
"tsUpdatedOn",
"tsUpdatedBy",
"total_package"
]

const dsrstatic_FactoryFactoryViewDetailsKeys = {
"carrier_booking_ref_number":"Carrier Booking Reference #",
"carrier_booking_release":"Carrier Release Order",
"vgm_cut_off":"VGM Cut off",
"shipping_instructions_cut_off":"SI Cut-off",
"hbl_number":"HBL #",
"arrival_notice":"Arrival Notice to Consignee",
"last_free_date_at_fpod":"Last Free Date @ FPOD",
"delivery_order_release":"Delivery Order Release",
"remarks":"Special Remarks",
}

const dsrstatic_DockFactoryViewDetailsKeys = {
"carrier_booking_ref_number":"Carrier Booking Reference #",
"carrier_booking_release":"Carrier Release Order",
"vgm_cut_off":"VGM Cut off",
"shipping_instructions_cut_off":"SI Cut-off",
"stuffing_confirmation":"Stuffing Confirmation",
"hbl_number":"HBL #",
"cargo_gatein_CFS": 'Cargo Gate-in at CFS',
"arrival_notice":"Arrival Notice to Consignee",
"last_free_date_at_fpod":"Last Free Date @ FPOD",
"delivery_order_release":"Delivery Order Release",
"remarks":"Special Remarks",
}

const dsrstatic_FactoryDockViewDetailsKeys = {
"carrier_booking_ref_number":"Carrier Booking Reference #",
"carrier_booking_release":"Carrier Release Order",
"vgm_cut_off":"VGM Cut off",
"shipping_instructions_cut_off":"SI Cut-off",
"hbl_number":"HBL #",
"arrival_notice":"Arrival Notice to Consignee",
"last_free_date_at_fpod":"Last Free Date @ FPOD",
"delivery_order_release":"Delivery Order Release",
"remarks":"Special Remarks",
}

const dsrstatic_DockDockViewDetailsKeys = {
"carrier_booking_ref_number":"Carrier Booking Reference #",
"carrier_booking_release":"Carrier Release Order",
"vgm_cut_off":"VGM Cut off",
"shipping_instructions_cut_off":"SI Cut-off",
"hbl_number":"HBL #",
"cargo_gatein_CFS": 'Cargo Gate-in at CFS',
"arrival_notice":"Arrival Notice to Consignee",
"last_free_date_at_fpod":"Last Free Date @ FPOD",
"delivery_order_release":"Delivery Order Release",
"remarks":"Special Remarks",
} 

const dsrstatic_AirViewDetailsKeys={
"airline_booking_release": "Airline Release Order",
"airline_booking_ref_number": "Airline Booking Reference #",
"flight_number_departure_airport":"Flight Number from Departure Airport",
"hawb_number": "HAWB Number",
"mawb_number": "MAWB Number",
"flight_number_ts1": "Flight Number from Transhipment 1 Airport",
"flight_number_ts2": "Flight Number from Transhipment 2 Airport",
'gatein_at_cargo':'Gate-in at Cargo Terminal',
"arrival_notice": "Arrival Notice to Consignee",
"last_free_date_at_fpod":"Last Free Date @ FPOD",
"delivery_order_release": "Delivery Order Released to Consignee",
"remarks": "Special Remarks",
}

const dsrstatic_LCLViewDetailsKeys={
'carrier':'Carrier',
'stuffing_confirmation':'Stuffing Confirmation',
"destuffing_at_pol":"De-stuffing Status",
"restuffing_at_pol":"Re-stuffing Status",
"pol_container_number":"POL Container #",
'hbl_number':'HBL Number',
'mbl_number':'MBL Number',
"cargo_gatein_CFS": 'Cargo Gate-in at CFS',
"destuffing_reworking":"De-stuffing @ Re-working Port",
"restuffing_reworking":"Re-stuffing @ Re-working Port",
'new_carrier_name':'New Carrier Name',
'new_carrier_mbl_number':'New Carrier MBL Number',
"reworking_container_number":"Re-working Container #",
"destuffing_fpod":"De-stuffing Status @ fpod",
"last_free_date_at_fpod":"Last Free Date @ FPOD",
'arrival_notice':'Arrival Notice to Consignee',
'delivery_order_release':'Delivery Order Release to Consignee',
'remarks':'Special Remarks',    
}

const originVehicleKeys = {
    "vehicle": "Vehicle#",
    "arrival_factory": "Arrival @ Factory",
    "cargo_pickup": "Cargo Pickup",
    "cargo_arrival_cfs": "Cargo arrival at CFS"
}

const destinationVehicleKey = {
    "vehicle": "Vehicle#",
    "container_number_vehicle":"Container Number",
    "number_of_package":"Number Of Package",
    "loaded_cfs": "Loaded @ CFS",
    "atdfrompod": "Cargo Picked-up for Delivery",
    "estimate_delivery_date_to_consignee": "Estimated Delivery Date to Consignee",
    "actual_delivery_date": "Actual Delivery Date"
}
const CoiCreationKey = {
    "personalDetails":"PersonalDetails",
    "entityName": "Business Name",
    "commodityType":"Sub Commodity",
    "lastName":"Last Name",
    "packageType": "Description Of Packaging",
    "phoneNo":"Phone No",
    "transitMode":"Transit Mode",
    "email":"Email",
    "policy_type":"Policy type",
    "gst":"GST Number",
    //"entityCountry":"Party State Name",
    "riskcommencementlocation":'Transit From Country',

    "address":'Address',
    "riskendinglocation":'Transit To Country',
    "city":"City ",

    //"clienttype":"Client Type ",
    "commericalInvoiceValue":"Commercial Invoice Value",
    "state":"State",

    "psd":"Policy Start Date",
    "pincode":"Pincode",

    "journeyDate":"Journey Date",
    "customercontact" :"Contact Person",

    "commericalInvoiceDate":"Invoice Date",
    "descriptionOfCargo":"Description Of Cargo",
    "containerized":"Containerized",
    "containersNo":"Container Number 1",
    "dateofCommencementofVoyage":"Date of Commencement of Voyage",
    "incoTerms":"Incoterms",
    "commodityType":"Commodity Type"
}

const origin_pickup_LCL = {
    arrival_factory: "Arrival at Factory",
    cargo_pickup: "Cargo pickup",
    cargo_arrival_cfs: "Cargo arrival at CFS",
  };

  const destination_delivery_LCL = {
    loaded_cfs: "Loaded at CFS",
    cargo_pickedup_date: "Cargo Pickedup date",
    estimate_delivery_date_to_consignee: "Estimated delivery date",
    actual_delivery_date: "Actual delivery date",
  };

  const origin_pickup_AIR = {
    arrival_factory: "Arrival at Factory",
    cargo_pickup: "Cargo pickup",
    arrival_dispatch_airport: "Arrival at dispatch airport",
  };

  const destination_delivery_AIR = {
    atdfrompod: "ETD at CFS",
    loaded_cfs: "Loaded at CFS",
    dispatched_from_arrival_airport: "Dispatched from airport",
    actual_delivery_date: "Actual delivery date",
  };

  const origin_pickup_fcl_FACTORY_FACTORY = {
    empty_container_arrival_at_factory: "Empty container arrival at factory",
    loaded_and_dispatched: "loaded and dispatched",
    gate_in_at_pol: "Gate-in @ POL",
    container_number_vehicle: "Container Number"
  };

  const destination_delivery_fcl_FACTORY_FACTORY = {
    container_pickup_at_fpod: "Container pickup @ FPOD",
    estimate_delivery_date_to_consignee: "Estimate delivery date to consignee",
    container_delivered_to_consignee: "Container delivered to consignee",
    container_number_vehicle: "Container Number"
  };

  const origin_pickup_fcl_FACTORY_CFS = {
    empty_container_arrival_at_factory: "Empty container arrival at factory",
    loaded_and_dispatched: "loaded and dispatched",
    gate_in_at_pol: "Gate-in @ POL",
    container_number_vehicle: "Container Number"
  };

  const destination_delivery_fcl_FACTORY_CFS = {
    loaded_cfs: "Loaded @ CFS",
    dispatched_from_cfs: "Dispatched from CFS",
    estimate_delivery_date_to_consignee: "Estimate delivery date to consignee",
    actual_delivery_date: "Actual delivery date",
  };

  const origin_pickup_fcl_CFS_CFS = {
    arrival_factory: "Arrival at Factory",
    cargo_pickup: "Cargo pickup",
    cargo_arrival_cfs: "Cargo arrival @CFS",
  };

  const destination_delivery_fcl_CFS_CFS = {
    loaded_cfs: "Loaded @ CFS",
    dispatched_from_cfs: "Dispatched from CFS",
    estimate_delivery_date_to_consignee: "Estimate delivery date to consignee",
    actual_delivery_date: "Actual delivery date",
  };

  const origin_pickup_fcl_CFS_FACTORY = {
    arrival_factory: "Arrival at Factory",
    cargo_pickup: "Cargo pickup",
    arrival_at_cfs: "Arrival at CFS",
  };

  const destination_delivery_fcl_CFS_FACTORY = {
    container_pickup_at_fpod: "Container pickup @ FPOD",
    estimate_delivery_date_to_consignee: "Estimate delivery date to consignee",
    container_delivered_to_consignee: "Container delivered to consignee",
    container_number_vehicle: "Container Number"
  };

export {
    dsrstatic_cn,
dsrstatic_FCLFactoryFactory,
dsrstatic_FCLFactoryFactory_keys,
dsrstatic_FCLDockFactory,
dsrstatic_FCLDockFactory_keys,
dsrstatic_FCLFactoryDock,
dsrstatic_FCLFactoryDock_keys,
dsrstatic_FCLDockDock,
dsrstatic_FCLDockDock_keys,
dsrstatic_LCL,
dsrstatic_LCL_keys,
dsrstatic_Air,
dsrstatic_Air_keys,
dsrstatic_FactoryFactoryViewDetailsKeys,
dsrstatic_DockFactoryViewDetailsKeys,
dsrstatic_FactoryDockViewDetailsKeys,
dsrstatic_DockDockViewDetailsKeys,
dsrstatic_AirViewDetailsKeys,
dsrstatic_LCLViewDetailsKeys,
originVehicleKeys,
destinationVehicleKey,
CoiCreationKey,
origin_pickup_LCL,
origin_pickup_fcl_FACTORY_FACTORY,
origin_pickup_fcl_FACTORY_CFS,
origin_pickup_fcl_CFS_FACTORY,
origin_pickup_fcl_CFS_CFS,
origin_pickup_AIR,
destination_delivery_LCL,
destination_delivery_fcl_FACTORY_FACTORY,
destination_delivery_fcl_FACTORY_CFS,
destination_delivery_fcl_CFS_CFS,
destination_delivery_fcl_CFS_FACTORY,
destination_delivery_AIR
}